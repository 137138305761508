<script setup>
import { computed, ref } from 'vue'
import Button from '@/components/ui/Button.vue';
import config from '@/config'

let props = defineProps({
  loading: { type: Boolean },
  mintPrice: { type: Number, default: .2 },
  isWhitelist: { type: Boolean },
  canMint: { type: Boolean },
  maxMintQty: { type: Number, default: 3 },
  allowedQty: { type: Number, default: 3 },
  totalMinted: { type: Number, default: 0 },
  mintSupply: { type: Number, default: 5000 },
  started: { type: Boolean }
})
let emit = defineEmits(['mint'])

let qty = ref(1)
let maxMintUi = computed(() => Math.min(props.maxMintQty, props.allowedQty))
let ltText = computed(() => props.allowedQty !== 1 ? 'Lunartics' : 'Lunartic')
let mintCost = computed(() => ( qty.value * props.mintPrice ).toFixed(2))

let mint = () => emit('mint', { qty: qty.value })
let soldOut = computed(() => props.totalMinted >= props.mintSupply)
</script>

<template>
  <div class="mint-card-wrapper">
    <img src="@/images/sherlock.webp" class="sherlock-img" />
    <img src="@/images/puff-cloud.webp" class="puff-cloud-img" />
    <div class="mint-card">
      <div class="star-field" />
      <div class="mint-preview">
        <h1>Mint Your Lunartic</h1>
        <img src="@/images/lunartic-in-moon.webp" class="lunartic-moon" />
        <h3>Total Minted:</h3>
        <div class="mint-totals">
          <span class="mint-totals__minted">{{ totalMinted }}</span>
          <span class="mint-totals__divider">{{ '/' }}</span>
          <span class="mint-totals__supply">{{ mintSupply }}</span>
        </div>
      </div>
      <div class="mint-controls">
        <template v-if="!started">
          <div class="whitelist-denied">
            <img class="emoji-crying" src="@/images/emoji-crying.webp" alt="crying emoji" />
            <p class="whitelist-denied__primary">The sale hasn't started yet</p>
            <p class="whitelist-denied__secondary"></p>
            <slot name="countdown"></slot>
          </div>
        </template>
        <template v-else-if="soldOut">
          <h2>Sold Out!</h2>
          <img src="@/images/emoji-party.webp" alt="party emoji" class="sold-out-party" />
          <Button as="a" :href="config.openseaUrl" target="_blank" color="#1D76DE">BUY ON OPENSEA</Button>
        </template>
        <template v-else-if="canMint && started">
          <div class="mint-info">
            <template v-if="isWhitelist && allowedQty == 0">
              <p>
                Whitelist Allocation Filled!
                <img src="@/images/emoji-party.webp" alt="party emoji" />
              </p>
              <p style="margin-top: 36px;">Public Mint starting in</p>
              <slot name="countdown"></slot>
            </template>
            <template v-else-if="isWhitelist && allowedQty > 0">
              <p>
                You're on the Whitelist!
                <img src="@/images/emoji-party.webp" alt="party emoji" />
              </p>
              <p>You can mint {{ allowedQty }} {{ ltText }}</p>
            </template>
            <template v-else>
              <h2>Public Mints</h2>

              <p>You can mint {{ allowedQty }} {{ ltText }}</p>
            </template>
          </div>
          <template v-if="allowedQty > 0">
            <h2>Mint Quantity</h2>

            <div class="mint-selection">
              <button
                v-for="n in maxMintUi"
                :key="n"
                class="btn-qty"
                :class="{ selected: qty === n }"
                @click="qty = n"
              >{{ n }}</button>
            </div>

            <p class="mint-cost">
              <span>Total:</span>
              <span class="mint-cost__amount">{{ ` ${mintCost} ` }}</span>
              <span>ETH</span>
            </p>

            <button class="btn-mint" @click="mint">Mint Now</button>
          </template>
        </template>
        <template v-else>
          <div class="whitelist-denied">
            <img class="emoji-crying" src="@/images/emoji-crying.webp" alt="crying emoji" />
            <p class="whitelist-denied__primary">You're not on the Whitelist!</p>
            <p class="whitelist-denied__secondary">Please come back during public mint</p>
            <slot name="countdown"></slot>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.mint-card {
  --color-em: #f70057;

  border-radius: 20px;
  background-size: 150%;
  min-height: 437px;
  box-shadow: 8px 10px 0 rgba(0, 0, 0, 0.25);

  display: flex;
  overflow: hidden;
  margin: 24px;

  position: relative;
    background-color: #040a29;
  z-index: 2;

  .star-field {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("/images/stars-bright.webp");
    background-size: 150%;
    z-index: -1;
    mask: url("/images/star-mask.png");
    mask-position: 0px 50%;
    animation: twinkle 36s linear infinite;
  }
}

@keyframes twinkle {
  from {
    mask-position: 0px 50%;
  }
  to {
  mask-position: 1000px 50%;
  }
}

.mint-preview {
  flex: 1;
  padding: 46px 53px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    white-space: nowrap;
    font-size: 37px;
    @media (max-width: 900px) {
      font-size: 32px;
    }
  }

  @media (max-width: 900px) {
    padding: 22px 28px;
  }
}

.lunartic-moon {
  @media (max-width: 900px) {
    width: 142px;
  }
}

.mint-controls {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 46px 58px;
  background-color: #161e38;
  min-width: 403px;

  h1,
  h2,
  h3,
  h4 {
    font-family: "Poppins";
    text-transform: uppercase;
  }

  h2 {
    font-size: 25px;
  }

  @media (max-width: 900px) {
    min-width: initial;
    min-height: 330px;
    padding: 28px;

    h2 {
      font-size: 20px;
    }
  }
}

h1,
h2,
h3,
h4 {
  font-family: "Cubano";
  text-transform: uppercase;
}

h3 {
  font-size: 22px;
}

p {
  font-size: 22px;
  margin-bottom: 4px;

  @media (max-width: 900px) {
    font-size: 18px;
  }
}

.mint-totals {
  font-size: 48px;
  span {
    font-family: "Cubano";
  }

  @media (max-width: 900px) {
    font-size: 40px;
  }
}

.mint-totals__supply {
  color: var(--color-em);
}

button {
  border: none;
}

.mint-selection {
  margin-top: 11px;
  margin-bottom: 22px;

  & > * + * {
    margin-left: 11px;
  }
}

.mint-cost {
  font-size: 25px;
  font-weight: bold;
}
.mint-cost__amount {
  color: var(--color-em);
}

.btn-qty {
  font-size: 25px;
  border-radius: 10px;
  width: 49px;
  height: 49px;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  &.selected {
    background-color: #1f2c63;
    color: white;
  }

  @media (max-width: 900px) {
    width: 39px;
    height: 39px;
    font-size: 20px;
  }
}

.btn-mint {
  margin-top: 54px;
  padding: 16px 78px 22px;
  text-decoration: none;
  min-width: fit-content;
  font-family: "Cubano";
  font-size: 22px;
  text-transform: uppercase;
  color: white;
  font-style: normal;
  font-weight: 400;
  background: #f80057;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.44),
    inset 0px -5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  transition: all ease 200ms;
  cursor: pointer;

  &:hover {
    box-shadow: inset 0px 0px 0px rgba(255, 255, 255, 0.44),
      inset 0px -9px 0px rgba(0, 0, 0, 0.25);
  }

  &:active {
    box-shadow: inset 0px 0px 0px rgba(255, 255, 255, 0.44),
      inset 0px 0px 0px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 900px) {
    margin-top: 28px;
  }
}

.mint-info {
  margin-bottom: 34px;
  text-align: center;

  img {
    height: 1em;
  }

  @media (max-width: 900px) {
    margin-bottom: 18px;
  }
}

.emoji-crying {
  max-width: 128px;
}

.whitelist-denied {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
  }
  .whitelist-denied__primary {
    font-size: 29px;
    max-width: 285px;
  }
  .whitelist-denied__secondary {
    font-size: 22px;
    max-width: 285px;
    margin-top: 48px;
  }
}

.sold-out-party {
  margin: 36px 0;
}

.mint-card-wrapper {
  position: relative
}
.sherlock-img, .puff-cloud-img {
  position: absolute;
}
.sherlock-img {
  top: -120px;
  left: -260px;
}
.puff-cloud-img {
  bottom: -200px;
  right: -300px;
}

@media (max-width: 900px) {
  .mint-card {
    flex-direction: column;
    margin-top: 104px;
  }

  h1 {
    font-size: 32px;
  }

  .sherlock-img, .puff-cloud-img {
    display: none;
  }
}
</style>
