<template>
  <div class="walletModal__overlay" @click="exitModal"></div>
  <transition name="slide" appear>
    <div class="modal">
      <p>{{ modalPrompt }}</p>
      <Button class="wallet-provider" @click="handleMetaMask">
        <p><span v-if="!ethereum.hasLocalProvider">Get</span> MetaMask</p>
        <img src="../../images/metamask-icon.svg" />
      </Button>
      <br /><br />
      <Button class="wallet-provider" @click="handleWalletConnect">
        <p><span v-if="!ethereum.hasLocalProvider">Get</span> WalletConnect</p>
        <img src="../../images/walletconnect-logo.svg" />
      </Button>
    </div>
  </transition>
</template>

<script>
'use strict';

// Imports.
import { mapState } from 'vuex';
import store from '../../store';
import Button from '/src/components/ui/Button.vue';

// Set up the default component.
export default {
  emits: ['exit'],

  data() {
    return {
      show: true
    };
  },

  components: {
    Button
  },

  computed: {
    ...mapState({
      ethereum: state => state.ethers
    }),
    modalPrompt() {
      return 'Connect to your Wallet';
    }
  },

  methods: {
    async handleMetaMask() {
      if (this.ethereum.hasLocalProvider) {
        await store.dispatch('ethers/connectWallet', '', { root: true });
        this.$emit('exit');
        // If there is no local Ethereum provider, this button should direct the
        // user to install MetaMask.
      } else {
        window.open('https://www.metamask.io', '_blank');
      }
    },
    async handleWalletConnect() {
      await store.dispatch('ethers/connectWCProvider', '', { root: true });
      this.$emit('exit');
    },
    exitModal() {
      this.$emit('exit');
    }
  }
};
</script>

<style scoped>
.walletModal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 400px;
  padding: 40px;
  background: #f80057;
  box-shadow: inset 0px 3px 0px rgba(255, 255, 255, 0.44),
    inset 0px -8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  color: white;
  border-radius: 24px;
}

.modal > p {
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 600;
  text-align: center;
  font-family: 'Cubano';
}

.wallet-provider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
}

.wallet-provider > p {
  font-family: 'Cubano';
}

.slide-enter-active {
  transition: opacity 0.5s ease;
}

.slide-enter-from,
.slide-leave-active {
  opacity: 0;
}
</style>
