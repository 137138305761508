<template>
  <Ethereum :callback="initialize" />

  <Header transparentBg />

  <BGFixed class="bg" />

  <div class="page-wrapper">
    <div class="nft-container">
      <Skeletor v-if="isLoading" class="title" />
      <div class="title" v-else>
        <span>My Items</span>
        <div class="count">
          <span>{{ nfts?.length }}</span>
        </div>
      </div>
      <div class="myClones">
        <div class="cloneGrid" v-if="isLoading">
          <CloneSkeleton v-for="(item, index) in 6" :key="index" />
        </div>
        <div class="cloneGrid" v-else-if="!isLoading && nfts.length > 0">
          <Clone
            v-for="(nft, index) in nfts"
            :key="index"
            :clone="nft"
          />
          <a class="buy" href="https://opensea.io/" target="_blank">
            <img
              src="https://lunartics.s3.amazonaws.com/assets/buy-on-opensea.webp"
            />
            <p class="buy-text">BUY ON OPENSEA</p>
          </a>
        </div>
        <div class="none" v-else>
          No NFTs in wallet :(
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import initializeConfig from '/src/initialize-config';
import CloneSkeleton from './components/CloneSkeleton.vue'
import Clone from './components/Clone.vue'
import BGFixed from '/src/components/ui/BGFixed.vue'
import Header from '/src/pages/layout/Header.vue';

import Ethereum from '/src/components/common/Ethereum.vue';

let config;
const isLoading = ref(true);
const store = useStore();
const nfts = ref([]);
const currentTabIndex = ref(0);
const viewNft = ref(null);

let goBack = () => {
  viewNft.value = null
}

onMounted(() => {
  window.scroll(0, 0);
});

const initialize = async () => {
  isLoading.value = true;

  // load global config
  config = await initializeConfig();

  // retrieve shop config
  if (isConnected.value) {
    await loadAll();
  }

  isLoading.value = false;
};

const loadAll = async () => {
  await Promise.all([
    loadOwnedItems(),
  ]);
};

const isRevealed = (nft) => nft.metatdata?.attributes?.length > 0 || true

// Click handler for changing currentTabIndex.
const handleSelection = index => {
  currentTabIndex.value = index;
};

const currentAddress = computed(() => {
  return store.state.ethers.address;
});

// Loads logged in user's owned items.
const loadOwnedItems = async () => {
  if (isConnected.value && config != null) {
    //console.info("loading owned items at address=", config.itemCollections[config.forceNetwork]);
    await store.dispatch(
      'mint/loadItems',
      {
        mintStore: store.state.mint, // wallet address
        resolveMetadata: true,
      },
      {
        root: true
      }
    );
    nfts.value = store.state.mint.owned;
    //console.log(nfts.value)
  };
};

// Computed property for wallet connection.
const isConnected = computed(() => {
  return store.state.ethers.canSign;
});

watch(
  currentAddress, (curAdd, oldAdd) => {
    if (curAdd == null) {
      // wallet has locked or disconnected altogether
      store.state.ethers.canSign = false;
      store.dispatch('login/initialize');
    }

    // essentially waits until wallet is ready
    ensureWalletConnected(400000).then(loadAll).catch(err => { let do_nothing = err; });
  }
);

// this following function should NOT be required and
// we should be able to watch over isConnected
// TODO: either get the watch to work or move this into ethers.service
function ensureWalletConnected(timeout) {
  var start = Date.now();
  return new Promise(waitForWallet);

  // waitForWallet makes the decision whether the condition is met
  // or not met or the timeout has been exceeded which means
  // this promise will be rejected
  function waitForWallet(resolve, reject) {
    // check if wallet connect has ever been enabled in the past
    store.commit('checkWalletConnectStore');
    // if we have connected previously, dispatch automagic connection
    if (store.state?.walletconnect?.connected) {
      store.dispatch('ethers/connectWCProvider');
    }
    if (store.state?.ethers?.canSign)
      resolve(store.state.ethers.canSign);
    else if (timeout && (Date.now() - start) >= timeout)
      reject(new Error("timeout"));
    else
      setTimeout(waitForWallet.bind(this, resolve, reject), 30);
  }
}
</script>
<style scoped lang="scss">
.page-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.nft-container {
  position: relative;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 60px;
  padding-top: 200px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}

.bg {
  background-position: center 200px;
}

.title {
  display: flex;
  align-items: center;
  gap: 14px;
  height: 44px;
  max-width: 300px;
  span {
    font-family: 'Cubano';
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    color: #1b285d;
  }

  .count {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    min-width: 44px;
    width: fit-content;
    height: 44px;
    padding: 20px 14px;
    background: #f80057;
    box-shadow: inset -2px -2px 0px rgba(0, 0, 0, 0.25);

    span {
      color: white;
      font-size: 28px;
      position: relative;
      bottom: 2px;
    }
  }
}

.myClones {
  .text {
    font-size: 24;
    height: 100%;
    text-align: center;
    margin-top: 70px;
  }

  .none {
    font-family: 'Cubano';
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    color: #1b285d;
    height: 100%;
    text-align: center;
    margin-top: 70px;
  }

  .cloneGrid {
    width: 100%;
    margin-top: 10px;
    padding-top: 30px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 32px;

    .buy {
      cursor: pointer;
      transition: all ease 150ms;
      text-decoration: none;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
        margin-bottom: 12px;
      }

      .buy-text {
        text-shadow: 0px 0px 7px #fff;
        text-transform: uppercase;
        color: #23315b;
        font-weight: 700;
        font-size: 22px;
        text-align: center;
      }

      &:hover {
        transform: scale(1.05);
        .buy-text {
          color: #f80057;
        }
      }
    }
  }
}

@media (max-width: 1900px) {
  .background {
    background-image: url('https://lunartics.s3.amazonaws.com/assets/city-background-desktop.webp');
  }
}

@media (max-width: 949px) {
  .background {
    background-image: url('https://lunartics.s3.amazonaws.com/assets/city-background-mobile.webp');
  }
}

@media (max-width: 800px) {
  .nft-container {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 100px;
  }
}
</style>
