<template>
  <transition name="fade" mode="out-in">
    <div
      class="wallet-modal-container"
      v-if="store.state.alert.showWalletModal"
    >
      <WalletModal @exit="hideWalletModalDisplay" />
    </div>
  </transition>
  <div class="connectWalletWrapper">
    <Button @click="showWalletModal"> Connect Wallet </Button>
  </div>
</template>
<script>
// Imports.
import { useStore } from 'vuex';
import { computed } from 'vue';

// Component imports.
import WalletModal from '/src/pages/layout/WalletModal.vue';
import Button from '/src/components/ui/Button.vue';

export default {
  components: {
    WalletModal,
    Button
  },

  setup(props) {
    const store = useStore();

    const isWalletModalVisible = computed(() => {
      return store.state.alert.showWalletModal;
    });

    const showWalletModal = () => {
      store.dispatch('alert/showWalletModal', { root: true });
    };

    const hideWalletModal = () => {
      store.dispatch('alert/hideWalletModal', { root: true });
    };

    return { store, isWalletModalVisible, showWalletModal, hideWalletModal };
  }
};
</script>
<style scoped lang="scss">
.connectWalletWrapper {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
// Animations
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
