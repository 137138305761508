'use strict';

// Imports.

// Specified actions for mutating the alert state.
export default {
  async initialize({ dispatch, commit }) {
    commit('initialize');
  }
};
