<template>
  <div class="bg-fixed"></div>
</template>

<style lang="scss" scoped>
.bg-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #e8ae9c;
  background-image: url('https://lunartics.s3.amazonaws.com/assets/city-background.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  z-index: -1;
  
}
</style>
