<template>
  <div class="container">
    <div class="img">
      <Skeletor class="skeleton" />
    </div>
    <Skeletor class="text" />
  </div>
</template>
<script>
import { computed } from 'vue';

import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';

export default {
  components: {
    Skeletor
  },

  props: {},

  setup(props) {}
};
</script>
<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img {
    width: 100%;
    margin-bottom: 12px;

    &:after {
      content: '';
      display: block;
      padding-bottom: 90%;
    }

    .skeleton {
      height: 100%;
      width: 100%;
      border-radius: 20px;
    }
  }

  .text {
    border-radius: 20px;
    width: 70%;
    height: 20px;
  }
}
</style>
