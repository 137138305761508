import itemJSON from './ABIs/Tiny721.json';
import mintShopJSON from './ABIs/DropPresaleShop721.json';

const config = {
  infuraProjectId: '954e2f5992304238b4382298b5e9dea2',
  alchemyApiKey: {
    '0x4': '3UFuUZqwIV6g7mTvIZl30GYIFSkXqh_z',
    '0x5': 'SmRibxWnqII5gXgiuPDRiJT4gzA1vo7t'
  },

  networkPollingIntervals: {
    '0x1': 12000,
    '0x3': 12000,
    '0x4': 12000,
    '0x5': 12000,
    '0x2a': 12000
  },

  // paymentSplitter: {
  //   '0x1': '0x58449889F53b22E35D501ddA2F20A05680eA40e3',
  //   '0x4': '0xf6ab974e6f43E2E57001D1b2BB0d6E3ECB1eA6F4'
  // },

  itemCollections: {
    '0x1': ['0x3035Bb5FDd9597903776dA28e41614e1B2ca39BC'],
    '0x4': ['0xcc11dD007b1e0fb7F25CC218758ab8301B4c8d95'],
    '0x5': ['']
  },

  //tokenAddress: '0x0A69706BA644076d88814b9A071Bb2F5A7D36a71',

  shopAddress: {
    '0x1': '0x9f092029aA5c8dCa892C3f031c744e065abfe476',
    '0x4': '0xd6855d1B44262EED8ecE88ffB8A7308F0F687d07',
    '0x5': ''
  },

  // merklePreSaleAddress: {
  //   '0x1': '0xBB25019498c3332424631dfCDC2AF4223df7cED1',
  //   '0x4': '0xCAA3fa978851aDA84E7677B387C137fe0333914F'
  // },

  itemABI: itemJSON,
  mintShopABI: mintShopJSON,

  reservedMintCount: 500,
  mintPaddingPercent: 2,
  forceNetwork: '0x1',

  lang: {
    projectName: 'Lunartics',
    projectNameLegal: 'Lunartics',
    whitelistDescriptions: [
      'Whitelist #0 description',
      'Whitelist #1 description',
      'Whitelist #2 description'
    ]
  },

  discordUrl: 'https://discord.gg/yMFtM6Z94V',
  twitterUrl: 'https://twitter.com/the_Lunartics?s=20&t=XRkj39yJR_Ysgj9Ukdl4gw',
  openseaUrl: 'https://opensea.thelunartics.com',
};

export default config;
