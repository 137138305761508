<template>
  <div class="faq-container">
    <div class="title-wrapper">
      <span>F</span>
      <RocketA />
      <span>Q</span>
    </div>
    <div class="dropdown-wrapper">
      <DropdownItem
        class="dropdown-item"
        v-for="(item, index) in faqs"
        :key="index"
        :title="item.title"
        :description="item.description"
        :isRed="index % 2 === 1"
      />
    </div>
  </div>
</template>
<script setup>
import DropdownItem from './DropdownItem.vue';
import RocketA from '@/components/common/RocketA.vue'

const faqs = [
  {
    title: 'What is the mint price?',
    description: [
      'Ξ0.08 per NFT + gas.',
      'There is no change in mint price between whitelisted and public mint.'
    ]
  },
  {
    title: 'When will the Whitelist sale start?',
    description: [
      "The whitelist sale will start on April 26th, 12PM PST. Be sure to watch our social media outlets so you don't miss it!"
    ]
  },
  {
    title: 'How long will the Whitelist sale be open?',
    description: [
      'The whitelist sale will be open for 24 hours, a maximum of 1 mint per wallet! This is to avoid a gas war and ensure everyone has ample time to be able to purchase no matter the timezone! The whitelist is not oversubscribed, meaning, if you are on the whitelist and purchase within this window you will be able to mint.'
    ]
  },
  {
    title:
      'Is there a maximum mint limit per wallet during the whitelist mint?',
    description: [
      'You can mint a maximum of 1 Lunartic NFT from your whitelisted wallet address during this period!'
    ]
  },
  {
    title:
      'Can I mint during the whitelist mint period if I was not whitelisted?',
    description: [
      'No, you will be able to participate when the public mint opens which will start at the close of the whitelist mint period.'
    ]
  },
  {
    title: 'Wen Public mint start?',
    description: [
      'The public mint will start on April 27th. It will automatically begin once the 24 hour WL minting period has ended and yes, we have ensured that there will be a number of NFTs available for public mint so be on time! Stay tuned to our social outlets for more details!'
    ]
  },
  {
    title: 'Is there a maximum mint limit per transaction during public mint?',
    description: [
      'Yes, you can mint a maximum of 1 Lunartics NFT per wallet during public mint!'
    ]
  },
  {
    title: 'Will Lunartics be minted on ERC-721A?',
    description: ['Yes, Lunartics NFTs will be minted on ERC-721A.']
  },
  {
    title: 'How will minting work?',
    description: [
      'We will mint an “unrevealed” Lunartics NFT. On the reveal day, we will be setting the real metadata live and users will be able to “refresh metadata” on https://opensea.io/ to see their revealed Lunartic NFT!'
    ]
  },
  {
    title: 'Wen reveal?',
    description: [
      'Reveal will take place within 24 Hours after sell out! As mentioned above, on the reveal day we will be setting the real metadata live and users will be able to “refresh metadata” on https://opensea.io/ to see their revealed Lunartic NFT!'
    ]
  },
  {
    title: 'Can I participate in the public mint if I was on the whitelist?',
    description: ['No, there is a limit of 1 mint per wallet. If you would like to purchase during the public sale, please use a different account.']
  },
  {
    title: 'Can I mint from using a hardware wallet?',
    description: ['Yes, you can.']
  },
  {
    title: 'What are the Licensing rights / Intellectual property?',
    description: [
      'Lunartics holders are given commercial rights to their specific Lunartics NFT. You can sell its likeness, make derivatives of it or you can permit others to use it. It’s yours. We’ve given licensing rights over for the Lunartic NFTs, but not our logo, names (Lunartics), site images, or other marketing material. If it isn’t your Lunartics NFT, it isn’t your Intellectual Property. Most of these logos, names, and images related to Lunartics have either trademarked pending status or are the intellectual property of the artist(s).',
      'That said, just because our logo may appear somewhere on your Lunartics NFT, that does not give you the right to use that logo other than on the Lunartics NFT itself or on a derivative of that Lunartics NFT. You can’t take the logo and use it to brand an item for sale or use it for marketing a product. We don’t mind personal use or fan art usage of our logos, just so long as you don’t try and sell them. The bottom line here is to protect the control center, it is important that someone can’t pretend to be Lunartics and that they can’t pretend to be collaborating with us.',
      'Regarding the term “Lunartics”. We allow usage of the term Lunartics only when used in conjunction with your Lunartics #. For instance, you can’t use Lunartics alone on merch or NFTs, but you can use Lunartics#2383, Lunartics#1567, etc.',
      'Think of it like this: You own your Lunartics NFT, not Lunartics, so accurately representing yourself/brand/product, while not infringing on our brand, is important.',
      'Lunar-C Corp reserves the right to use any Art within The Lunartics series for any reason, including but not limited to including the Art as part of The Lunartics animations, without any obligations to you. All rights not expressly granted above are reserved by the Lunar-C Corp. For clarity, Lunar-C Corp. will have the right to utilize the Art as part of The Lunartics animations to generate revenue and will be solely entitled to retain any such revenue.'
    ]
  }
];
</script>
<style scoped lang="scss">
.faq-container {
  padding: 100px 30px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;

  .title-wrapper {
    display: flex;
    font-size: 64px;
    align-items: flex-end;
    gap: 5px;
    margin-bottom: 10px;
    span {
      font-family: 'Cubano';
    }
    .a {
      width: 36px;
      height: auto;
      object-fit: contain;
    }
  }

  .dropdown-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .dropdown-item {
      width: 100%;
    }
  }
}
</style>
