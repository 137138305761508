<script setup>
import { ref, computed } from 'vue';

let container = ref(null)
let item = computed(() => container.value && container.value.querySelector('div.animation > div:first-of-type'))

let sliderWidth = 1400
let animationWidth = 273 * 6

let sliderHeight = computed(() => item.value && item.value?.offsetHeight)
let slidesVisible = computed(() => {
  if (!container.value || !item.value) {
    return null
  }

  console.log('measure', container.value.offsetWidth, item.value.offsetWidth)
  return Math.ceil(container.value.offsetWidth / item.value?.offsetWidth)
})
let slidesNumber = computed(() => container.value && container.value.querySelectorAll('div.animation > div').length)
let speed = computed(() => slidesNumber.value * 2)
let animationWidthCss = computed(() => `-${animationWidth}px`)
</script>

<template>
  <div class="block" ref="container">
    <div class="animation">
      <div class="first">
        <img src="@/images/lt01.png" />
      </div>
      <div>
        <img src="@/images/lt02.png" />
      </div>
      <div>
        <img src="@/images/lt03.png" />
      </div>
      <div>
        <img src="@/images/lt04.png" />
      </div>
      <div>
        <img src="@/images/lt05.png" />
      </div>
      <div>
        <img src="@/images/lt06.png" />
      </div>
      <div>
        <img src="@/images/lt07.webp" />
      </div>
      <div>
        <img src="@/images/lt08.webp" />
      </div>
      <div>
        <img src="@/images/lt09.webp" />
      </div>
      <div>
        <img src="@/images/lt05.png" />
      </div>
      <!-- cut -->
      <div>
        <img src="@/images/lt01.png" />
      </div>
      <div>
        <img src="@/images/lt02.png" />
      </div>
      <div>
        <img src="@/images/lt03.png" />
      </div>
      <div>
        <img src="@/images/lt04.png" />
      </div>
      <div>
        <img src="@/images/lt05.png" />
      </div>
      <div>
        <img src="@/images/lt06.png" />
      </div>
      <div>
        <img src="@/images/lt07.webp" />
      </div>
      <div>
        <img src="@/images/lt08.webp" />
      </div>
      <div>
        <img src="@/images/lt09.webp" />
      </div>
      <div>
        <img src="@/images/lt05.png" />
      </div>
      <!-- cut -->
      <div>
        <img src="@/images/lt01.png" />
      </div>
      <div>
        <img src="@/images/lt02.png" />
      </div>
      <div>
        <img src="@/images/lt03.png" />
      </div>
      <div>
        <img src="@/images/lt04.png" />
      </div>
      <div>
        <img src="@/images/lt05.png" />
      </div>
      <div>
        <img src="@/images/lt06.png" />
      </div>
      <div>
        <img src="@/images/lt07.webp" />
      </div>
      <div>
        <img src="@/images/lt08.webp" />
      </div>
      <div>
        <img src="@/images/lt09.webp" />
      </div>
      <div>
        <img src="@/images/lt05.png" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.block {
  overflow: hidden;
  width: 100%;
  margin: 90px auto;
}
.animation {
  --timing: 6s;
  --scrollTiming: 36s;
  --imageWidth: 253px;

  @media (max-width: 840px) {
    --imageWidth: 136px;
  }

  width: calc(100% + 100%);
  height: calc(var(--imageWidth) + 36px);
  font-size: 0px;
  display: flex;
  div {
    --numImages: 10;
    --animationWidthCss: calc((var(--imageWidth) + 20px) * var(--numImages) * -1);
    display: inline-block;
    width: auto;
    float: none;
    padding: 0 10px;
    img {
      width: var(--imageWidth);
      border-radius: 22px;
    }

    &:nth-child(even) {
      img {
        /* transform: translateY(36px); */
        animation: verticalshift var(--timing) ease-in-out infinite alternate;
      }
    }
    &:nth-child(odd) {
      img {
        /* transform: translateY(36px); */
        animation: verticalshift2 var(--timing) ease-in-out infinite alternate;
      }
    }
  }
}

@keyframes scroll {
  0% {
    /* margin-left: 0px; */
    transform: translateX(0);
  }
  100% {
    /* margin-left: v-bind('animationWidthCss'); */
    /* transform: translateX(v-bind('animationWidthCss')); */
    transform: translateX(var(--animationWidthCss));
  }
}

@keyframes verticalshift {
  0% {
    /* margin-left: 0px; */
    transform: translateY(0);
  }
  100% {
    /* margin-left: v-bind('animationWidthCss'); */
    transform: translateY(36px);
  }
}

@keyframes verticalshift2 {
  0% {
    /* margin-left: 0px; */
    transform: translateY(36px);
  }
  100% {
    /* margin-left: v-bind('animationWidthCss'); */
    transform: translateY(0);
  }
}

.animation > div {
  animation: scroll var(--scrollTiming) linear infinite;
}
</style>
