<template>
  <Ethereum :callback="initialize" />
  <Header transparentBg :hideLogo="true" />

  <SplashArea />
  <About />
  <ImageScroller />
  <Roadmap id="roadmap" />
  <ImageScroller />
  <div class="gradient-bg">
    <Traits />
    <Team id="team" />
    <DiscordSection />
    <FaqSection id="faq" />
  </div>

  <ExplosionGraphic />
  <Footer isHome />
</template>
<script setup>
import Ethereum from '/src/components/common/Ethereum.vue';
import Header from '/src/pages/layout/Header.vue';
import About from './components/About.vue';
import DiscordSection from './components/DiscordSection.vue';
import ImageScroller from './components/ImageScroller.vue';
import Roadmap from './components/Roadmap.vue';
import SplashArea from './components/SplashArea.vue';
import Team from './components/Team.vue';
import Traits from './components/Traits.vue';
import FaqSection from './components/FAQSection.vue';
import ExplosionGraphic from './components/ExplosionGraphic.vue';
import Footer from '/src/pages/layout/Footer.vue';
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const isLoggedIn = computed(() => {
  return store.state.ethers.address;
});

const initialize = async () => { };
</script>
<style scoped lang="scss">
.home {
  text-align: center;
  padding: 50px;
}

.gradient-bg {
  background: linear-gradient(180deg, #040615 0%, #0d2294 65.38%, #e8ae9c 100%);
}
</style>
