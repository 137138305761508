<template>
  <div class="explosion-graphic-wrapper">
    <img
      src="https://lunartics.s3.amazonaws.com/assets/sherlock-explosion.webp"
    />

    <div class="background" />
  </div>
</template>
<script setup></script>
<style scoped lang="scss">
.explosion-graphic-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    max-width: 1000px;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: #e8ae9c;
    background-image: url('https://lunartics.s3.amazonaws.com/assets/city-background.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    z-index: -1;
  }
}

@media (max-width: 1900px) {
  .background {
    background-image: url('https://lunartics.s3.amazonaws.com/assets/city-background-desktop.webp');
  }
}

@media (max-width: 949px) {
  .background {
    background-image: url('https://lunartics.s3.amazonaws.com/assets/city-background-mobile.webp');
  }
}
</style>
