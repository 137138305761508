<template>
  <div>
  </div>
</template>

<script setup>
import useEthereum from '/src/utility/useEthereum'

let props = defineProps({
  callback: {
    type: Function,
    required: true
  }
})

useEthereum(props.callback)
</script>
