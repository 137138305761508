<template>
  <div class="terms">
    <h1>Privacy Policy</h1>

    <p class="c39">
      <span class="c34">The Lunartics Privacy Policy</span>
    </p>
    <p class="c29">
      <span class="c3"></span>
    </p>
    <p class="c33">
      <span class="c34">Last Updated:</span>
      <span class="c9">&nbsp;April 7, 2022</span>
    </p>
    <p class="c7" id="h.gjdgxs">
      <span class="c14">
        This Privacy Policy is designed to help you understand how Lunar-C Corp.
        (&ldquo;
      </span>
      <span class="c8">Lunar-C</span>
      <span class="c14">,&rdquo; &ldquo;</span>
      <span class="c8">we</span>
      <span class="c14">,&rdquo; &ldquo;</span>
      <span class="c8">us</span>
      <span class="c14">
        ,&rdquo;
        and &ldquo;
      </span>
      <span class="c8">our</span>
      <span class="c14">
        &rdquo;) collects, uses, and shares your personal
        information on The Lunartics website located at
      </span>
      <span class="c24">
        <a
          class="c11"
          href="https://www.google.com/url?q=https://thelunartics.com/&amp;sa=D&amp;source=editors&amp;ust=1650406487425999&amp;usg=AOvVaw3OPnx_CIaiI9Z5oZevsV86"
        >https://thelunartics.com/</a>
      </span>
      <span>
        &nbsp;(the
        &ldquo;
      </span>
      <span class="c34">Site</span>
      <span>&rdquo;)</span>
      <span class="c9">
        and to help you understand and
        exercise your privacy rights.
      </span>
    </p>
    <a id="id.1fob9te"></a>
    <ol class="c1 lst-kix_list_10-0 start" start="1">
      <li class="c2">
        <h1 id="h.30j0zll" style="display:inline">
          <span class="c3">SCOPE</span>
        </h1>
      </li>
    </ol>
    <ul class="c1 lst-kix_list_4-0 start">
      <li class="c2">
        <h1 style="display:inline">
          <span class="c30 c14">
            This Privacy Policy applies to personal information processed by
            Lunar-C on
          </span>
          <span class="c30">the Site.</span>
        </h1>
      </li>
    </ul>
    <ol class="c1 lst-kix_list_10-0" start="2">
      <li class="c2">
        <h1 id="h.2et92p0" style="display:inline">
          <span class="c30 c14">&nbsp;</span>
          <a id="id.3znysh7"></a>
          <span class="c3">CHANGES TO OUR PRIVACY POLICY</span>
        </h1>
      </li>
    </ol>
    <ul class="c1 lst-kix_list_4-0">
      <li class="c2">
        <h1 style="display:inline">
          <span class="c9">
            We may revise this Privacy Policy from time to time in our sole
            discretion. If there are any material changes to this Privacy Policy, we will notify you as required by
            applicable law. You understand and agree that you will be deemed to have accepted the updated Privacy Policy
            if you continue to use our Site after the new Privacy Policy takes effect.
          </span>
        </h1>
      </li>
    </ul>
    <ol class="c1 lst-kix_list_10-0" start="3">
      <li class="c2">
        <h1 style="display:inline">
          <span class="c3">PERSONAL INFORMATION WE COLLECT</span>
        </h1>
      </li>
    </ol>
    <p class="c7">
      <span class="c9">
        The categories of personal information we collect depend on how you interact with us,
        our Site, and the requirements of applicable law. We collect information that you provide to us, information we
        obtain automatically when you use our Site, and information from other sources such as third-party services and
        organizations, as described below.
      </span>
    </p>
    <a id="id.tyjcwt"></a>
    <ol class="c1 lst-kix_list_11-0 start" start="1">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Information You Provide to Us Directly</span>
      </li>
    </ol>
    <p class="c7">
      <span class="c9">We may collect the following personal information that you provide to us.</span>
    </p>
    <ul class="c1 lst-kix_list_6-0 start">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Your Communications with Us</span>
        <span class="c14">
          . We may collect
          personal information, such as email address, when you request information about our Site or otherwise
          communicate with us.
        </span>
      </li>
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Interactive Features</span>
        <span class="c14">
          . We and others who use
          our Site may collect personal information that you submit or make available through our interactive features
          (e.g., commenting functionalities and social media pages). Any personal information you provide on the public
          sections of these features will be considered &ldquo;public,&rdquo; unless otherwise required by applicable law,
          and is not subject to the privacy protections referenced herein.
        </span>
      </li>
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Sweepstakes or Contests</span>
        <span class="c14">
          . We may collect
          personal information you provide for any sweepstakes or contests that we offer. In some jurisdictions, we are
          required to publicly share information of sweepstakes and contest winners.
        </span>
      </li>
    </ul>
    <ol class="c1 lst-kix_list_11-0" start="2">
      <li class="c7 c20 li-bullet-0">
        <span class="c3">Information Collected Automatically</span>
      </li>
    </ol>
    <p class="c7">
      <span class="c9">We may collect personal information automatically when you use our Site:</span>
    </p>
    <ul class="c1 lst-kix_list_6-0">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Automatic Data Collection</span>
        <span class="c9">
          . We may collect
          certain information automatically when you use our Site, such as your Internet protocol (IP) address, user
          settings, cookie identifiers, browser or device information, location information (including approximate
          location derived from IP address), and Internet service provider. We may also automatically collect information
          regarding your use of our Site, such as pages that you visit before, during and after using our Site,
          information about the links you click, the types of content you interact with, the frequency and duration of
          your activities, and other information about how you use our Site.
        </span>
      </li>
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Cookies, Pixel Tags/Web Beacons, and Other Technologies</span>
        <span class="c14">
          . We, as well as third parties that provide content, advertising, or other functionality on our
          Site, may use cookies, pixel tags, local storage, and other technologies (&ldquo;
        </span>
        <span class="c8">Technologies</span>
        <span class="c9">
          &rdquo;) to automatically collect information through your use of
          our Site.
        </span>
      </li>
    </ul>
    <ul class="c1 lst-kix_list_8-0 start">
      <li class="c27 li-bullet-0">
        <span class="c8">Cookies</span>
        <span class="c9">
          . Cookies are small text files placed in
          device browsers that store preferences and facilitate and enhance your experience.
        </span>
      </li>
      <li class="c27 li-bullet-0">
        <span class="c8">Pixel Tags/Web Beacons</span>
        <span class="c9">
          . A pixel tag (also known
          as a web beacon) is a piece of code embedded in our Site that collects information about engagement on our Site.
          The use of a pixel tag allows us to record, for example, that a user has visited a particular web page or
          clicked on a particular advertisement.
        </span>
      </li>
    </ul>
    <p class="c7 c38">
      <span class="c9">Our uses of these Technologies fall into the following general categories:</span>
    </p>
    <ul class="c1 lst-kix_list_8-0">
      <li class="c27 li-bullet-0">
        <span class="c8">Operationally Necessary</span>
        <span class="c9">
          . This includes
          Technologies that allow you access to our Site, applications, and tools that are required to identify irregular
          website behavior, prevent fraudulent activity and improve security or that allow you to make use of our
          functionality;
        </span>
      </li>
      <li class="c27 li-bullet-0">
        <span class="c8">Performance-Related</span>
        <span class="c14">
          . We may use Technologies
          to assess the performance of our Site, including as part of our analytic practices to help us understand how
          individuals use our Site (
        </span>
        <span class="c22 c14">see</span>
        <span class="c21">
          <a class="c11" href="#id.2grqrue">Analytics</a>
        </span>
        <span class="c14 c22">&nbsp;below</span>
        <span class="c9">);</span>
      </li>
      <li class="c27 li-bullet-0">
        <span class="c8">Functionality-Related</span>
        <span class="c9">
          . We may use Technologies
          that allow us to offer you enhanced functionality when accessing or using our Site. This may include identifying
          you when you sign into our Site or keeping track of your specified preferences, interests, or past items viewed;
          and
        </span>
      </li>
    </ul>
    <ul class="c1 lst-kix_list_12-0 start">
      <li class="c27 li-bullet-0">
        <span class="c8">Advertising- or Targeting-Related</span>
        <span class="c14">
          . We may use
          first party or third-party Technologies to deliver content, including ads relevant to your interests, on our
          Site or on third-party websites.
        </span>
      </li>
    </ul>
    <p class="c7 c38">
      <span class="c22 c14">See</span>
      <span class="c21">
        <a class="c11" href="#id.3j2qqm3">
          Section
          6
        </a>
      </span>
      <span class="c22 c14">&nbsp;below to understand your choices regarding these Technologies.</span>
    </p>
    <ul class="c1 lst-kix_list_6-0">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Analytics</span>
        <span class="c9">
          . We may use Technologies and other
          third-party tools to process analytics information on our Site.
        </span>
      </li>
    </ul>
    <ul class="c1 lst-kix_list_6-1 start">
      <li class="c28 li-bullet-0">
        <span class="c3">Google Analytics</span>
        <span class="c9">
          . For more information about
          how Google uses your data (including for its own purposes, e.g., for profiling or linking it to other data),
          please visit
        </span>
        <span class="c16">
          <a
            class="c11"
            href="https://www.google.com/url?q=http://www.google.com/policies/privacy/partners/&amp;sa=D&amp;source=editors&amp;ust=1650406487429745&amp;usg=AOvVaw0A2KHOrVUrUFBWXA4rykCh"
          >
            Google
            Analytics&rsquo; Privacy Policy
          </a>
        </span>
        <span class="c9">
          . To learn more about how to opt-out of Google
          Analytics&rsquo; use of your information, please click
        </span>
        <span class="c16">
          <a
            class="c11"
            href="https://www.google.com/url?q=http://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;source=editors&amp;ust=1650406487430082&amp;usg=AOvVaw0xAzzTMh6eAFI9uh5VUmg9"
          >here</a>
        </span>
        <span class="c9">.</span>
      </li>
    </ul>
    <ul class="c1 lst-kix_list_6-0">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Social Media Platforms.</span>
        <span class="c14">
          Our Site may
          contain
        </span>
        <span class="c8">&nbsp;</span>
        <span class="c9">
          social media buttons such as Twitter and Discord
          (that might include widgets such as the &ldquo;share this&rdquo; button or other interactive mini programs).
          These features may collect your IP address, which page you are visiting on our Site, and may set a cookie to
          enable the feature to function properly. Your interactions with these platforms are governed by the privacy
          policy of the relevant corporate entities providing such platforms.
        </span>
      </li>
    </ul>
    <ol class="c1 lst-kix_list_11-0" start="3">
      <li class="c7 c20 li-bullet-0">
        <span class="c3">Information Collected from Other Sources</span>
      </li>
    </ol>
    <ul class="c1 lst-kix_list_6-0">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Third-Party Sources.</span>
        <span class="c9">
          &nbsp;We may obtain
          information about you from other sources, including through third-party services and organizations. For example,
          if you access our Site through a third-party application, such as a third-party login service or a social
          networking site, we may collect information about you from that third-party application that you have made
          available via your privacy settings.
        </span>
      </li>
    </ul>
    <a id="id.3dy6vkm"></a>
    <ol class="c1 lst-kix_list_10-0" start="4">
      <li class="c2">
        <h1 id="h.1t3h5sf" style="display:inline">
          <span class="c3">HOW WE USE YOUR INFORMATION</span>
        </h1>
      </li>
    </ol>
    <p class="c7" id="h.4d34og8">
      <span class="c9">
        We use your information for a variety of business purposes, including to
        provide our Site, for administrative purposes and to market our products and services, as described below.
      </span>
    </p>
    <ol class="c1 lst-kix_list_1-0 start" start="1">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Provide our Site</span>
      </li>
    </ol>
    <p class="c7">
      <span class="c9">
        We use your information to fulfill our contract with you and provide you with our Site,
        including but not limited to:
      </span>
    </p>
    <ul class="c1 lst-kix_list_2-0 start">
      <li class="c5 li-bullet-0">
        <span class="c9">Managing your information;</span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c9">
          Providing access to certain areas, functionalities, and features of our
          Site;
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c9">
          Communicating with you about your activities on our Site and policy
          changes;
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c9">
          Processing your digital wallet information to facilitate transactions
          via the Site; and
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c9">Allowing you to register for events.</span>
      </li>
    </ul>
    <p class="c13">
      <span class="c9"></span>
    </p>
    <ol class="c1 lst-kix_list_1-0" start="2">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Administrative Purposes</span>
      </li>
    </ol>
    <p class="c33 c41 subtitle">
      <span class="c9">
        We use your information for various administrative purposes, including
        but not limited to:
      </span>
    </p>
    <ul class="c1 lst-kix_list_2-0">
      <li class="c5 li-bullet-0">
        <span class="c9">
          Pursuing our legitimate interests such as research and development
          (including marketing research), network and information security, and fraud prevention;
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c9">
          Detecting security incidents, protecting against malicious, deceptive,
          fraudulent or illegal activity, and prosecuting those responsible for that activity;
        </span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c9">Measuring interest and engagement in our Site;</span>
      </li>
      <li class="c5 li-bullet-0" id="h.2s8eyo1">
        <span class="c9">Improving, upgrading or enhancing our Site;</span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c9">Developing new products and Site;</span>
      </li>
      <li class="c5 li-bullet-0">
        <span class="c9">Ensuring internal quality control and safety;</span>
      </li>
    </ul>
    <ul class="c1 lst-kix_list_3-0 start">
      <li class="c19 li-bullet-0">
        <span class="c9">Authenticating and verifying individual identities;</span>
      </li>
      <li class="c19 li-bullet-0">
        <span class="c9">Debugging to identify and repair errors with our Site;</span>
      </li>
      <li class="c19 li-bullet-0">
        <span class="c9">
          Auditing relating to interactions, transactions and other compliance
          activities;
        </span>
      </li>
      <li class="c19 li-bullet-0">
        <span class="c9">Enforcing our agreements and policies; and</span>
      </li>
      <li class="c20 c40 li-bullet-0">
        <span class="c9">Complying with our legal obligations.</span>
      </li>
    </ul>
    <ol class="c1 lst-kix_list_1-0" start="3">
      <li class="c35 c20 li-bullet-0">
        <span class="c8">Marketing our Products and Services</span>
      </li>
    </ol>
    <p class="c35">
      <span class="c9">
        We may use personal information to tailor and provide you with content. We may provide
        you with these materials as permitted by applicable law.
      </span>
    </p>
    <p class="c7 c41 subtitle">
      <span class="c30">
        If you have any questions about our marketing practices or if you would
        like to opt out of the use of your personal information for marketing purposes, you may contact us at any time as
        set forth in
      </span>
      <span class="c24 c30">
        <a class="c11" href="#id.2u6wntf">Section 14</a>
      </span>
      <span class="c9">&nbsp;below.</span>
    </p>
    <ol class="c1 lst-kix_list_1-0" start="4">
      <li class="c35 c20 li-bullet-0">
        <span class="c8">Other Purposes</span>
      </li>
    </ol>
    <p class="c7">
      <span class="c9">
        We also use your information for other purposes as requested by you or as permitted by
        applicable law.
      </span>
    </p>
    <ul class="c1 lst-kix_list_4-0">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Consent</span>
        <span class="c9">
          . We may use personal information for
          other purposes that are clearly disclosed to you at the time you provide personal information or with your
          consent.
        </span>
      </li>
      <li class="c7 c20 li-bullet-0">
        <span class="c8">De-identified and Aggregated Information.</span>
        <span class="c9">
          &nbsp;We may use personal information and other information about you to create de-identified and/or
          aggregated information, such as de-identified demographic information, de-identified location information,
          information about the device from which you access our Site, or other analyses we create.
        </span>
      </li>
    </ul>
    <a id="id.26in1rg"></a>
    <a id="id.17dp8vu"></a>
    <a id="id.3rdcrjn"></a>
    <a id="id.1ksv4uv"></a>
    <a id="id.35nkun2"></a>
    <ol class="c1 lst-kix_list_10-0" start="5">
      <li class="c7 c43" id="h.lnxbz9">
        <span class="c3">HOW WE DISCLOSE YOUR INFORMATION</span>
      </li>
    </ol>
    <p class="c7">
      <span class="c9">
        We disclose your information to third parties for a variety of business purposes,
        including to provide our Site, to protect us or others, or in the event of a major business transaction such as a
        merger, sale, or asset transfer, as described below.
      </span>
    </p>
    <ol class="c1 lst-kix_list_7-0 start" start="1">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Disclosures to Provide our Site</span>
      </li>
    </ol>
    <p class="c7">
      <span class="c9">
        The categories of third parties with whom we may share your information are described
        below.
      </span>
    </p>
    <ul class="c1 lst-kix_list_9-0 start">
      <li class="c7 c23 li-bullet-0">
        <span class="c34">Notice Regarding Use of Blockchain.</span>
        <span class="c9">
          &nbsp;If
          you elect to sell, purchase, or trade any Lunartics NFTs, any financial transactions that you engage in will be
          conducted solely through the Blockchain network governing such NFT and/or on the NFT marketplace platform (such
          as OpenSea) where you will trade such NFTs. Information about your transactions will be provided to the
          applicable Blockchain and may be accessible to third parties due to the nature of the Blockchain.
        </span>
      </li>
      <li class="c7 c23 li-bullet-0">
        <span class="c8">Third Party Websites and Applications.</span>
        <span class="c14">
          You
          may choose to share personal information or interact with third-party websites and/or third-party applications
          which may reference or link to our Site, including, but not limited to, third-party electronic wallet extensions
          or NFT marketplaces (e.g., OpenSea). Once your personal information has been shared with a third-party website
          or a third-party application, it will also be subject to such third party&rsquo;s privacy policy. We encourage
          you to closely read each third-party website or third-party application privacy policy before sharing your
          personal information or otherwise interacting with them. Please note that we do not control and we are not
          responsible for the third-party website&rsquo;s or the third-party application&rsquo;s processing of your
          personal information, or the privacy practices or content of such other websites or applications.
        </span>
        <span>Providing personal information to third-party websites or applications is at your own risk.</span>
      </li>
      <li class="c7 c23 li-bullet-0">
        <span class="c8">Service Providers</span>
        <span class="c9">
          . We may share your
          personal information with our third-party service providers who use that information to help us provide our
          Site. This includes service providers that provide us with IT support, hosting, payment processing, customer
          service, and related services.
        </span>
      </li>
      <li class="c7 c23 li-bullet-0">
        <span class="c3">Business Partners</span>
        <span class="c9">
          . We may share your
          personal information with business partners to provide you with a product or service you have requested. We may
          also share your personal information with business partners with whom we jointly offer products or
          services.
        </span>
      </li>
      <li class="c7 c23 li-bullet-0">
        <span class="c8">Affiliates</span>
        <span class="c9">
          . We may share your personal
          information with members of our corporate family and our affiliates.
        </span>
      </li>
      <li class="c7 c23 li-bullet-0">
        <span class="c8">APIs/SDKs</span>
        <span class="c14">
          . We may use third-party
          application program interfaces (&ldquo;
        </span>
        <span class="c8">APIs</span>
        <span class="c14">
          &rdquo;) and
          software development kits (&ldquo;
        </span>
        <span class="c8">SDKs</span>
        <span class="c14">
          &rdquo;) as part of the
          functionality of our Site. For more information about our use of APIs and SDKs, please contact us as set forth
          in
        </span>
        <span class="c24">
          <a class="c11" href="#id.2u6wntf">Section 14</a>
        </span>
        <span class="c9">&nbsp;below.</span>
      </li>
    </ul>
    <ol class="c1 lst-kix_list_7-0" start="2">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Disclosures to Protect Us or Others</span>
      </li>
    </ol>
    <p class="c7" id="h.44sinio">
      <span class="c9">
        We may access, preserve, and disclose any information we store
        associated with you to external parties if we, in good faith, believe doing so is required or appropriate to: (i)
        comply with law enforcement or national security requests and legal process, such as a court order or subpoena;
        (ii) protect your, our, or others&rsquo; rights, property, or safety; (iii) enforce our policies or contracts;
        (iv) collect amounts owed to us; or (v) assist with an investigation or prosecution of suspected or actual illegal
        activity.
      </span>
    </p>
    <ol class="c1 lst-kix_list_7-0" start="3">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">
          Disclosure in the Event of Merger, Sale, or Other Asset
          Transfers
        </span>
      </li>
    </ol>
    <p class="c7">
      <span class="c9">
        If we are involved in a merger, acquisition, financing due diligence, reorganization,
        bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, your
        information may be sold or transferred as part of such a transaction, as permitted by law and/or contract.
      </span>
    </p>
    <a id="id.2jxsxqh"></a>
    <a id="id.3j2qqm3"></a>
    <ol class="c1 lst-kix_list_10-0" start="6">
      <li class="c2">
        <h1 id="h.z337ya" style="display:inline">
          <span class="c3">YOUR PRIVACY CHOICES AND RIGHTS</span>
        </h1>
      </li>
    </ol>
    <a id="id.1y810tw"></a>
    <p class="c7">
      <span class="c8">Your Privacy Choices</span>
      <span class="c9">
        . The privacy choices you may have about
        your personal information are determined by applicable law and are described below.
      </span>
    </p>
    <a id="id.4i7ojhp"></a>
    <ul class="c1 lst-kix_list_4-0">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">&ldquo;Do Not Track</span>
        <span class="c14">.</span>
        <span class="c8">&rdquo;</span>
        <span class="c14">&nbsp;Do Not Track (&ldquo;</span>
        <span class="c8">DNT</span>
        <span class="c9">
          &rdquo;) is a privacy preference that users can set in certain web browsers. Please note that we do
          not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.
        </span>
      </li>
    </ul>
    <a id="id.2xcytpi"></a>
    <ul class="c1 lst-kix_list_4-0">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Cookies</span>
        <span class="c9">
          . You may stop or restrict the
          placement of Technologies on your device or remove them by adjusting your preferences as your browser or device
          permits. However, if you adjust your preferences, our Site may not work properly.
        </span>
      </li>
    </ul>
    <p class="c7 c38">
      <span class="c9">Please note you must separately opt out in each browser and on each device.</span>
    </p>
    <a id="id.1ci93xb"></a>
    <p class="c7">
      <span class="c8">Your Privacy Rights</span>
      <span class="c9">
        . In accordance with applicable law, you may
        have the right to:
      </span>
    </p>
    <ul class="c1 lst-kix_list_4-0">
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Access Personal Information</span>
        <span class="c14">
          about you,
          including: (i) confirming whether we are processing your personal information; (ii) obtaining access to or a
          copy of your personal information; or (iii) receiving an electronic copy of personal information that you have
          provided to us, or asking us to send that information to another company (aka the right of data
          portability);
        </span>
      </li>
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Request Correction</span>
        <span class="c14">
          of your personal
          information where it is inaccurate or incomplete. In some cases, we may provide self-service tools that enable
          you to update your personal information;
        </span>
      </li>
      <li class="c7 c20 li-bullet-0">
        <span class="c8">Request Deletion</span>
        <span class="c14">
          of your personal
          information;
        </span>
        <span class="c3">&nbsp;</span>
      </li>
      <li class="c7 c20 li-bullet-0" id="h.3whwml4">
        <span class="c8">Request Restriction of or Object</span>
        <span class="c14">&nbsp;to our processing of your personal information; and</span>
      </li>
      <li class="c19 li-bullet-0">
        <span class="c8">Withdraw Your Consent</span>
        <span class="c14">
          to our processing of
          your personal information.
        </span>
        <span class="c3">&nbsp;</span>
      </li>
    </ul>
    <p class="c4">
      <span class="c9"></span>
    </p>
    <p class="c25">
      <span
        class="c14"
      >If you would like to exercise any of these rights, please contact us as set forth in</span>
      <span class="c24">
        <a class="c11" href="#id.2u6wntf">Section 14</a>
      </span>
      <span class="c9">
        &nbsp;below. We
        will process such requests in accordance with applicable laws.
      </span>
    </p>
    <a id="id.2bn6wsx"></a>
    <a id="id.3as4poj"></a>
    <ul class="c1 lst-kix_list_4-0">
      <li class="c4 c41 c38 c44">
        <h1 id="h.qsh70q" style="display:inline">
          <span class="c3"></span>
        </h1>
      </li>
    </ul>
    <ol class="c1 lst-kix_list_10-0" start="7">
      <li class="c10">
        <h1 style="display:inline">
          <span class="c3">SECURITY OF YOUR INFORMATION</span>
        </h1>
      </li>
    </ol>
    <p class="c4">
      <span class="c9"></span>
    </p>
    <p class="c25">
      <span class="c14">
        We take steps designed to ensure that your information is treated securely and in
        accordance with this Privacy Policy.
      </span>
      <span class="c22 c14">&nbsp;</span>
      <span class="c9">
        Unfortunately, no
        system is 100% secure, and we cannot ensure or warrant the security of any information you provide to us. To the
        fullest extent permitted by applicable law, we do not accept liability for unauthorized disclosure.
      </span>
    </p>
    <p class="c4">
      <span class="c9"></span>
    </p>
    <p class="c25" id="h.1pxezwc">
      <span class="c14">
        By using our Site or providing personal information to us, you agree
        that we may communicate with you electronically regarding security, privacy, and administrative issues relating to
        your use of our Site. If we learn of a security system&rsquo;s breach, we may attempt to notify you electronically
        by posting a notice on our Site, by mail, or by sending an email to you.
      </span>
      <a id="id.49x2ik5"></a>
      <a id="id.2p2csry"></a>
      <span class="c9">&nbsp;</span>
    </p>
    <p class="c4 c12">
      <span class="c9"></span>
    </p>
    <ol class="c1 lst-kix_list_10-0" start="8">
      <li class="c10">
        <h1 style="display:inline">
          <span class="c3">INTERNATIONAL DATA TRANSFERS</span>
        </h1>
      </li>
    </ol>
    <p class="c4">
      <span class="c9"></span>
    </p>
    <p class="c7">
      <span class="c9">
        All information processed by us may be transferred, processed, and stored anywhere in
        the world, including, but not limited to, the United States or other countries, which may have data protection
        laws that are different from the laws where you live. We endeavor to safeguard your information consistent with
        the requirements of applicable laws.
      </span>
    </p>
    <a id="id.147n2zr"></a>
    <a id="id.23ckvvd"></a>
    <ol class="c1 lst-kix_list_10-0" start="9">
      <li class="c2">
        <h1 id="h.3o7alnk" style="display:inline">
          <span class="c3">RETENTION OF PERSONAL INFORMATION</span>
        </h1>
      </li>
    </ol>
    <p class="c7">
      <span class="c9">
        We store the personal information we collect as described in this Privacy Policy for as
        long as you use our Site or as necessary to fulfill the purpose(s) for which it was collected, provide our Site,
        resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our
        agreements, and comply with applicable laws. &nbsp;
      </span>
    </p>
    <ol class="c1 lst-kix_list_10-0" start="10">
      <li class="c2">
        <h1 id="h.ihv636" style="display:inline">
          <span class="c3">SUPPLEMENTAL NOTICE FOR CALIFORNIA RESIDENTS</span>
        </h1>
      </li>
    </ol>
    <p class="c25">
      <span>
        This Supplemental California Privacy Policy only applies to our processing of personal
        information that is subject to the California Consumer Privacy Act of 2018 (&ldquo;
      </span>
      <span class="c34">CCPA</span>
      <span class="c9">
        &rdquo;). The CCPA provides California residents with the right to know
        what categories of personal information Lunar-C has collected about them, and whether Lunar-C disclosed that
        personal information for a business purpose (e.g., to a service provider) in the preceding twelve months.
        California residents can find this information below:
      </span>
    </p>
    <p class="c4">
      <span class="c9"></span>
    </p>
    <a id="t.e58480dc45c04bb989f02caabd1832c6a9e9340d"></a>
    <a id="t.0"></a>
    <table class="c15">
      <tbody>
        <tr class="c0">
          <td class="c26" colspan="1" rowspan="1">
            <p class="c39">
              <span class="c3">Category of Personal Information Collected by Lunar-C</span>
            </p>
          </td>
          <td class="c32" colspan="1" rowspan="1">
            <p class="c31">
              <span class="c3">
                Categories of Third Parties Personal Information is Disclosed to for a
                Business Purpose
              </span>
            </p>
          </td>
        </tr>
        <tr class="c0">
          <td class="c26" colspan="1" rowspan="1">
            <p class="c17">
              <span class="c3">Identifiers</span>
            </p>
            <p class="c17">
              <span>Internet Protocol address, email address, or other similar identifiers.</span>
            </p>
          </td>
          <td class="c32" colspan="1" rowspan="1">
            <ul class="c1 lst-kix_list_5-0 start">
              <li class="c6 li-bullet-0">
                <span class="c9">Service providers</span>
              </li>
              <li class="c6 li-bullet-0">
                <span class="c9">Third-party websites or applications (wallet providers)</span>
              </li>
              <li class="c6 li-bullet-0">
                <span class="c9">Blockchain networks</span>
              </li>
              <li class="c6 li-bullet-0">
                <span class="c9">Affiliates</span>
              </li>
            </ul>
            <p class="c18">
              <span class="c9"></span>
            </p>
          </td>
        </tr>
        <tr class="c0">
          <td class="c26" colspan="1" rowspan="1">
            <p class="c17">
              <span class="c3">Internet or other electronic network activity</span>
            </p>
            <p class="c17">
              <span>
                Browsing history, search history, or information on a consumer&#39;s interaction with an
                internet website.
              </span>
            </p>
          </td>
          <td class="c32" colspan="1" rowspan="1">
            <ul class="c1 lst-kix_list_5-0">
              <li class="c6 li-bullet-0">
                <span class="c9">Service providers</span>
              </li>
              <li class="c6 li-bullet-0">
                <span class="c9">Affiliates</span>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="c4">
      <span class="c9"></span>
    </p>
    <p class="c25">
      <span class="c9">
        The categories of sources from which we collect personal information and our business
        and commercial purposes for using personal information are set forth above.
      </span>
    </p>
    <p class="c4">
      <span class="c3"></span>
    </p>
    <p class="c25">
      <span class="c3">Additional Privacy Rights for California Residents</span>
    </p>
    <p class="c4">
      <span class="c3"></span>
    </p>
    <p class="c25">
      <span class="c8">&ldquo;Sales&rdquo; of Personal Information under the CCPA.</span>
      <span class="c14">
        For purposes of the CCPA, Lunar-C does not &ldquo;sell&rdquo; personal information, nor do we have
        actual knowledge of any &ldquo;sale&rdquo; of personal information of minors under 16 years of age.
      </span>
    </p>
    <p class="c4">
      <span class="c3"></span>
    </p>
    <p class="c25">
      <span class="c8">Non-Discrimination.</span>
      <span class="c14">
        &nbsp;California residents have the right
        not to receive discriminatory treatment by us for the exercise of their rights conferred by the CCPA.
      </span>
      <span class="c8">&nbsp;</span>
    </p>
    <p class="c37">
      <span class="c8">Authorized Agent.</span>
      <span class="c14">
        Only you, or someone legally authorized to
        act on your behalf, may make a verifiable consumer request related to your personal information. To designate an
        authorized agent, please contact us as set forth in
      </span>
      <span class="c24">
        <a class="c11" href="#id.2u6wntf">Section 14</a>
      </span>
      <span class="c9">&nbsp;below.</span>
    </p>
    <p class="c37">
      <span class="c8">Verification</span>
      <span class="c9">
        . When you make a request, we will ask you to
        provide sufficient information that allows us to reasonably verify you are the person about whom we collected
        personal information or an authorized representative, which may include confirming the email address associated
        with any personal information we have about you.
      </span>
    </p>
    <p class="c4">
      <span class="c9"></span>
    </p>
    <p class="c25">
      <span class="c14">
        If you are a California resident and would like to exercise any of your rights under
        the CCPA, please contact us as set forth in
      </span>
      <span class="c24">
        <a class="c11" href="#id.2u6wntf">
          Section
          14
        </a>
      </span>
      <span
        class="c9"
      >&nbsp;below. We will process such requests in accordance with applicable laws.</span>
    </p>
    <p class="c4">
      <span class="c9"></span>
    </p>
    <ol class="c1 lst-kix_list_10-0" start="11">
      <li class="c2">
        <h1 id="h.32hioqz" style="display:inline">
          <span class="c3">SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</span>
        </h1>
      </li>
    </ol>
    <p class="c25">
      <span>
        If you are a resident of Nevada, you have the right to opt-out of the sale of certain personal
        information to third parties who intend to license or sell that personal information. You can exercise this right
        by contacting us as set forth in
      </span>
      <span class="c24">
        <a class="c11" href="#id.2u6wntf">
          Section
          14
        </a>
      </span>
      <span class="c14">&nbsp;</span>
      <span class="c9">
        below with the subject line &ldquo;Nevada Do Not
        Sell Request&rdquo; and providing us with your name and the email address associated with your account. Please
        note that we do not currently sell your personal information as sales are defined in Nevada Revised Statutes
        Chapter 603A.
      </span>
    </p>
    <ol class="c1 lst-kix_list_10-0" start="12">
      <li class="c35 c41 c43">
        <h1 id="h.1hmsyys" style="display:inline">
          <span class="c3">CHILDREN&rsquo;S INFORMATION</span>
        </h1>
      </li>
    </ol>
    <p class="c25">
      <span class="c14">
        The Site is not directed to children under 13 (or other age as required by local
        law), and we do not knowingly collect personal information from children. If you learn that your child has
        provided us with personal information without your consent, you may contact us as set forth in
      </span>
      <span class="c24">
        <a class="c11" href="#id.2u6wntf">Section 14</a>
      </span>
      <span class="c9">
        &nbsp;below. &nbsp;If we learn
        that we have collected a child&rsquo;s personal information in violation of applicable law, we will promptly take
        steps to delete such information.
      </span>
    </p>
    <p class="c4">
      <span class="c9"></span>
    </p>
    <ol class="c1 lst-kix_list_10-0" start="13">
      <li class="c2">
        <h1 id="h.41mghml" style="display:inline">
          <span class="c3">SUPERVISORY AUTHORITY</span>
        </h1>
      </li>
    </ol>
    <p class="c25" id="h.1v1yuxt">
      <span class="c9">
        If you are located in the European Economic Area, Switzerland, or the
        United Kingdom you have the right to lodge a complaint with a supervisory authority if you believe our processing
        of your personal information violates applicable law.
      </span>
      <a id="id.3fwokq0"></a>
      <a id="id.vx1227"></a>
      <a id="id.2grqrue"></a>
    </p>
    <p class="c4">
      <span class="c3"></span>
    </p>
    <ol class="c1 lst-kix_list_10-0" start="14">
      <li class="c2">
        <h1 style="display:inline">
          <span class="c3">CONTACT US</span>
        </h1>
      </li>
    </ol>
    <p class="c25" id="h.4f1mdlm">
      <span>
        If you have any questions about our privacy practices or this Privacy Policy, or
        to exercise your rights as detailed in this Privacy Policy, please contact us via our
      </span>
      <span class="c24">
        <a
          class="c11"
          href="https://www.google.com/url?q=https://discord.gg/lunartics&amp;sa=D&amp;source=editors&amp;ust=1650406487448386&amp;usg=AOvVaw3bZEAw9zXqK-BzHmS-EXYv"
        >
          Discord
          channel
        </a>
      </span>
      <span>&nbsp;or at&nbsp;</span>
      <span class="c24">
        <a class="c11" href="mailto:info@thelunartics.com">info@thelunartics.com</a>
      </span>
      <span class="c9">.</span>
    </p>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import config from '/src/config';

export default {
  mounted() {
    window.scroll(0, 0);
  },

  data() {
    return {
      projectNameLegal: config.lang.projectNameLegal
    };
  },

  setup() {
    const router = useRouter();
    const goBack = () => {
      router.go(-1);
    };

    return {
      goBack
    };
  }
};
</script>
<style scoped lang="scss">
.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: rgb(29, 29, 29);
  padding: 10px 0px;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  animation-duration: 7s;
  animation-name: rainbowBorder;
  animation-iteration-count: infinite;

  align-items: center;
  button {
    margin-left: 60px;
    font-size: 14px;
    font-family: PressStart;
    animation-duration: 10s;
    animation-name: rainbowText, rainbowBorder;
    animation-iteration-count: infinite;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid white;
    transition: background-color 200ms ease-in;
    color: white;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      animation-name: rainbowBackground;
    }

    &:active {
      animation-name: none;
      background-color: rgb(255, 0, 191);
    }
  }

  img {
    height: 100px;
    width: 200px;
    justify-self: center;
  }
}
.terms {
  padding: 100px;
  padding-top: 130px;
  max-width: 1600px;
  color: white;
  text-align: left;
  margin: auto;
  height: 100%;
  font-size: 16px;

  > h1 {
    font-family: "Cubano";
    font-size: 50px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .section {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

a {
  color: rgb(255, 0, 191);
}

@media (max-width: 700px) {
  .terms {
    padding: 30px;
    padding-top: 120px;
  }
}

table {
  width: 100%;
}

ol.lst-kix_list_7-0 {
  list-style-type: none;
}

.lst-kix_list_8-1 > li {
  counter-increment: lst-ctn-kix_list_8-1;
}

ol.lst-kix_list_8-2.start {
  counter-reset: lst-ctn-kix_list_8-2 0;
}

ul.lst-kix_list_9-3 {
  list-style-type: none;
}

ul.lst-kix_list_9-4 {
  list-style-type: none;
}

ul.lst-kix_list_9-1 {
  list-style-type: none;
}

ul.lst-kix_list_9-2 {
  list-style-type: none;
}

ul.lst-kix_list_9-7 {
  list-style-type: none;
}

ol.lst-kix_list_7-4.start {
  counter-reset: lst-ctn-kix_list_7-4 0;
}

ul.lst-kix_list_9-8 {
  list-style-type: none;
}

ul.lst-kix_list_9-5 {
  list-style-type: none;
}

.lst-kix_list_7-0 > li {
  counter-increment: lst-ctn-kix_list_7-0;
}

ul.lst-kix_list_9-6 {
  list-style-type: none;
}

ol.lst-kix_list_7-5 {
  list-style-type: none;
}

.lst-kix_list_11-0 > li {
  counter-increment: lst-ctn-kix_list_11-0;
}

ol.lst-kix_list_7-6 {
  list-style-type: none;
}

ol.lst-kix_list_7-7 {
  list-style-type: none;
}

ol.lst-kix_list_7-8 {
  list-style-type: none;
}

ol.lst-kix_list_7-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}

ol.lst-kix_list_7-2 {
  list-style-type: none;
}

ol.lst-kix_list_11-8.start {
  counter-reset: lst-ctn-kix_list_11-8 0;
}

ul.lst-kix_list_9-0 {
  list-style-type: none;
}

ol.lst-kix_list_7-3 {
  list-style-type: none;
}

ol.lst-kix_list_7-4 {
  list-style-type: none;
}

.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}

ol.lst-kix_list_11-2.start {
  counter-reset: lst-ctn-kix_list_11-2 0;
}

ol.lst-kix_list_8-8.start {
  counter-reset: lst-ctn-kix_list_8-8 0;
}

ol.lst-kix_list_10-4.start {
  counter-reset: lst-ctn-kix_list_10-4 0;
}

.lst-kix_list_7-2 > li {
  counter-increment: lst-ctn-kix_list_7-2;
}

ol.lst-kix_list_8-7.start {
  counter-reset: lst-ctn-kix_list_8-7 0;
}

.lst-kix_list_5-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}

ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}

.lst-kix_list_5-3 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_5-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_8-3 > li {
  counter-increment: lst-ctn-kix_list_8-3;
}

.lst-kix_list_5-1 > li:before {
  content: "o  ";
}

.lst-kix_list_5-7 > li:before {
  content: "o  ";
}

.lst-kix_list_5-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_5-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_5-4 > li:before {
  content: "o  ";
}

.lst-kix_list_5-5 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_8-0 {
  list-style-type: none;
}

.lst-kix_list_10-3 > li {
  counter-increment: lst-ctn-kix_list_10-3;
}

ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}

.lst-kix_list_6-1 > li:before {
  content: "o  ";
}

.lst-kix_list_6-3 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_6-4 > li:before {
  content: "o  ";
}

.lst-kix_list_6-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_6-8 > li:before {
  content: "\0025aa  ";
}

ol.lst-kix_list_11-7.start {
  counter-reset: lst-ctn-kix_list_11-7 0;
}

.lst-kix_list_6-5 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_6-7 > li:before {
  content: "o  ";
}

.lst-kix_list_6-6 > li:before {
  content: "\0025cf  ";
}

ol.lst-kix_list_1-3 {
  list-style-type: none;
}

ol.lst-kix_list_10-6.start {
  counter-reset: lst-ctn-kix_list_10-6 0;
}

ol.lst-kix_list_1-4 {
  list-style-type: none;
}

.lst-kix_list_2-7 > li:before {
  content: "o  ";
}

ol.lst-kix_list_1-5 {
  list-style-type: none;
}

.lst-kix_list_7-4 > li:before {
  content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". ";
}

.lst-kix_list_7-6 > li:before {
  content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
}

ol.lst-kix_list_1-6 {
  list-style-type: none;
}

ol.lst-kix_list_1-0 {
  list-style-type: none;
}

.lst-kix_list_2-5 > li:before {
  content: "\0025aa  ";
}

ol.lst-kix_list_1-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-2 {
  list-style-type: none;
}

.lst-kix_list_7-2 > li:before {
  content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". ";
}

.lst-kix_list_7-6 > li {
  counter-increment: lst-ctn-kix_list_7-6;
}

.lst-kix_list_8-6 > li {
  counter-increment: lst-ctn-kix_list_8-6;
}

ol.lst-kix_list_10-3.start {
  counter-reset: lst-ctn-kix_list_10-3 0;
}

ul.lst-kix_list_3-7 {
  list-style-type: none;
}

ul.lst-kix_list_3-8 {
  list-style-type: none;
}

.lst-kix_list_10-1 > li:before {
  content: "(" counter(lst-ctn-kix_list_10-1, upper-latin) ") ";
}

ul.lst-kix_list_3-1 {
  list-style-type: none;
}

ul.lst-kix_list_3-2 {
  list-style-type: none;
}

.lst-kix_list_7-7 > li {
  counter-increment: lst-ctn-kix_list_7-7;
}

.lst-kix_list_7-8 > li:before {
  content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". ";
}

ul.lst-kix_list_3-0 {
  list-style-type: none;
}

ol.lst-kix_list_1-7 {
  list-style-type: none;
}

ul.lst-kix_list_3-5 {
  list-style-type: none;
}

ol.lst-kix_list_1-8 {
  list-style-type: none;
}

ul.lst-kix_list_3-6 {
  list-style-type: none;
}

ul.lst-kix_list_3-3 {
  list-style-type: none;
}

ul.lst-kix_list_3-4 {
  list-style-type: none;
}

.lst-kix_list_10-7 > li:before {
  content: "" counter(lst-ctn-kix_list_10-7, lower-roman) ") ";
}

.lst-kix_list_7-8 > li {
  counter-increment: lst-ctn-kix_list_7-8;
}

.lst-kix_list_10-5 > li:before {
  content: "(" counter(lst-ctn-kix_list_10-5, lower-latin) ") ";
}

.lst-kix_list_10-3 > li:before {
  content: "(" counter(lst-ctn-kix_list_10-3, upper-roman) ") ";
}

.lst-kix_list_4-1 > li:before {
  content: "o  ";
}

ol.lst-kix_list_7-3.start {
  counter-reset: lst-ctn-kix_list_7-3 0;
}

.lst-kix_list_11-7 > li {
  counter-increment: lst-ctn-kix_list_11-7;
}

.lst-kix_list_9-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-3 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_4-5 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}

.lst-kix_list_10-5 > li {
  counter-increment: lst-ctn-kix_list_10-5;
}

ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}

ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}

.lst-kix_list_9-0 > li:before {
  content: "\0025cf  ";
}

ol.lst-kix_list_10-7 {
  list-style-type: none;
}

.lst-kix_list_9-6 > li:before {
  content: "\0025cf  ";
}

ol.lst-kix_list_10-8 {
  list-style-type: none;
}

ol.lst-kix_list_10-3 {
  list-style-type: none;
}

.lst-kix_list_11-2 > li {
  counter-increment: lst-ctn-kix_list_11-2;
}

.lst-kix_list_9-4 > li:before {
  content: "o  ";
}

ol.lst-kix_list_10-4 {
  list-style-type: none;
}

ol.lst-kix_list_10-5 {
  list-style-type: none;
}

.lst-kix_list_11-3 > li:before {
  content: "" counter(lst-ctn-kix_list_11-3, decimal) ". ";
}

ol.lst-kix_list_10-6 {
  list-style-type: none;
}

ol.lst-kix_list_10-0 {
  list-style-type: none;
}

ol.lst-kix_list_10-1 {
  list-style-type: none;
}

ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}

ul.lst-kix_list_2-8 {
  list-style-type: none;
}

ol.lst-kix_list_10-2 {
  list-style-type: none;
}

ol.lst-kix_list_8-8 {
  list-style-type: none;
}

.lst-kix_list_12-3 > li:before {
  content: "\0025cf  ";
}

ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}

ol.lst-kix_list_7-6.start {
  counter-reset: lst-ctn-kix_list_7-6 0;
}

.lst-kix_list_11-5 > li:before {
  content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". ";
}

ol.lst-kix_list_8-4 {
  list-style-type: none;
}

.lst-kix_list_12-1 > li:before {
  content: "o  ";
}

ul.lst-kix_list_2-2 {
  list-style-type: none;
}

ol.lst-kix_list_8-5 {
  list-style-type: none;
}

ul.lst-kix_list_2-3 {
  list-style-type: none;
}

ol.lst-kix_list_8-6 {
  list-style-type: none;
}

ul.lst-kix_list_2-0 {
  list-style-type: none;
}

ol.lst-kix_list_8-7 {
  list-style-type: none;
}

ul.lst-kix_list_2-1 {
  list-style-type: none;
}

.lst-kix_list_9-8 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_2-6 {
  list-style-type: none;
}

ol.lst-kix_list_8-1 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li:before {
  content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". ";
}

ul.lst-kix_list_2-7 {
  list-style-type: none;
}

.lst-kix_list_11-7 > li:before {
  content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". ";
}

ol.lst-kix_list_8-2 {
  list-style-type: none;
}

ul.lst-kix_list_2-4 {
  list-style-type: none;
}

ol.lst-kix_list_8-3 {
  list-style-type: none;
}

ul.lst-kix_list_2-5 {
  list-style-type: none;
}

.lst-kix_list_8-5 > li {
  counter-increment: lst-ctn-kix_list_8-5;
}

.lst-kix_list_1-3 > li:before {
  content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
}

.lst-kix_list_10-4 > li {
  counter-increment: lst-ctn-kix_list_10-4;
}

ol.lst-kix_list_10-5.start {
  counter-reset: lst-ctn-kix_list_10-5 0;
}

.lst-kix_list_1-7 > li:before {
  content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
}

.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}

.lst-kix_list_1-5 > li:before {
  content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". ";
}

.lst-kix_list_12-5 > li:before {
  content: "\0025aa  ";
}

ol.lst-kix_list_7-5.start {
  counter-reset: lst-ctn-kix_list_7-5 0;
}

.lst-kix_list_12-7 > li:before {
  content: "o  ";
}

.lst-kix_list_2-1 > li:before {
  content: "o  ";
}

.lst-kix_list_2-3 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_11-8 > li {
  counter-increment: lst-ctn-kix_list_11-8;
}

ol.lst-kix_list_11-6 {
  list-style-type: none;
}

ol.lst-kix_list_11-7 {
  list-style-type: none;
}

ol.lst-kix_list_11-8 {
  list-style-type: none;
}

.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}

.lst-kix_list_7-1 > li {
  counter-increment: lst-ctn-kix_list_7-1;
}

ol.lst-kix_list_11-2 {
  list-style-type: none;
}

ol.lst-kix_list_11-3 {
  list-style-type: none;
}

.lst-kix_list_3-0 > li:before {
  content: "\0025cf  ";
}

ol.lst-kix_list_11-4 {
  list-style-type: none;
}

ol.lst-kix_list_7-7.start {
  counter-reset: lst-ctn-kix_list_7-7 0;
}

ol.lst-kix_list_11-5 {
  list-style-type: none;
}

ul.lst-kix_list_5-7 {
  list-style-type: none;
}

ul.lst-kix_list_5-8 {
  list-style-type: none;
}

.lst-kix_list_3-1 > li:before {
  content: "o  ";
}

.lst-kix_list_3-2 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_5-5 {
  list-style-type: none;
}

ol.lst-kix_list_11-0 {
  list-style-type: none;
}

ul.lst-kix_list_5-6 {
  list-style-type: none;
}

ol.lst-kix_list_11-1 {
  list-style-type: none;
}

.lst-kix_list_8-1 > li:before {
  content: " ";
}

ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}

.lst-kix_list_8-2 > li:before {
  content: " ";
}

.lst-kix_list_3-5 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_5-0 {
  list-style-type: none;
}

.lst-kix_list_10-0 > li {
  counter-increment: lst-ctn-kix_list_10-0;
}

.lst-kix_list_3-4 > li:before {
  content: "o  ";
}

ul.lst-kix_list_5-3 {
  list-style-type: none;
}

.lst-kix_list_3-3 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_5-4 {
  list-style-type: none;
}

ul.lst-kix_list_5-1 {
  list-style-type: none;
}

.lst-kix_list_8-0 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_5-2 {
  list-style-type: none;
}

.lst-kix_list_8-7 > li:before {
  content: " ";
}

ol.lst-kix_list_11-5.start {
  counter-reset: lst-ctn-kix_list_11-5 0;
}

.lst-kix_list_3-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_8-5 > li:before {
  content: " ";
}

ol.lst-kix_list_10-7.start {
  counter-reset: lst-ctn-kix_list_10-7 0;
}

.lst-kix_list_11-1 > li {
  counter-increment: lst-ctn-kix_list_11-1;
}

.lst-kix_list_8-6 > li:before {
  content: " ";
}

.lst-kix_list_8-3 > li:before {
  content: " ";
}

.lst-kix_list_3-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_3-7 > li:before {
  content: "o  ";
}

.lst-kix_list_8-4 > li:before {
  content: " ";
}

.lst-kix_list_11-2 > li:before {
  content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". ";
}

.lst-kix_list_10-2 > li {
  counter-increment: lst-ctn-kix_list_10-2;
}

ol.lst-kix_list_8-5.start {
  counter-reset: lst-ctn-kix_list_8-5 0;
}

.lst-kix_list_11-1 > li:before {
  content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ". ";
}

.lst-kix_list_11-0 > li:before {
  content: "" counter(lst-ctn-kix_list_11-0, upper-latin) ". ";
}

.lst-kix_list_8-8 > li:before {
  content: " ";
}

ol.lst-kix_list_7-2.start {
  counter-reset: lst-ctn-kix_list_7-2 0;
}

ol.lst-kix_list_11-6.start {
  counter-reset: lst-ctn-kix_list_11-6 0;
}

ol.lst-kix_list_10-1.start {
  counter-reset: lst-ctn-kix_list_10-1 0;
}

.lst-kix_list_4-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-7 > li:before {
  content: "o  ";
}

ul.lst-kix_list_4-8 {
  list-style-type: none;
}

ul.lst-kix_list_4-6 {
  list-style-type: none;
}

ul.lst-kix_list_4-7 {
  list-style-type: none;
}

.lst-kix_list_7-3 > li {
  counter-increment: lst-ctn-kix_list_7-3;
}

.lst-kix_list_8-4 > li {
  counter-increment: lst-ctn-kix_list_8-4;
}

ul.lst-kix_list_4-0 {
  list-style-type: none;
}

ul.lst-kix_list_4-1 {
  list-style-type: none;
}

ol.lst-kix_list_10-8.start {
  counter-reset: lst-ctn-kix_list_10-8 0;
}

ul.lst-kix_list_4-4 {
  list-style-type: none;
}

ul.lst-kix_list_4-5 {
  list-style-type: none;
}

ul.lst-kix_list_4-2 {
  list-style-type: none;
}

ul.lst-kix_list_4-3 {
  list-style-type: none;
}

.lst-kix_list_11-3 > li {
  counter-increment: lst-ctn-kix_list_11-3;
}

ol.lst-kix_list_7-8.start {
  counter-reset: lst-ctn-kix_list_7-8 0;
}

ol.lst-kix_list_7-1.start {
  counter-reset: lst-ctn-kix_list_7-1 0;
}

ol.lst-kix_list_11-0.start {
  counter-reset: lst-ctn-kix_list_11-0 0;
}

ul.lst-kix_list_12-6 {
  list-style-type: none;
}

ol.lst-kix_list_8-6.start {
  counter-reset: lst-ctn-kix_list_8-6 0;
}

ul.lst-kix_list_12-5 {
  list-style-type: none;
}

ul.lst-kix_list_12-4 {
  list-style-type: none;
}

ul.lst-kix_list_12-3 {
  list-style-type: none;
}

ul.lst-kix_list_12-2 {
  list-style-type: none;
}

ul.lst-kix_list_12-1 {
  list-style-type: none;
}

ol.lst-kix_list_10-2.start {
  counter-reset: lst-ctn-kix_list_10-2 0;
}

ul.lst-kix_list_12-0 {
  list-style-type: none;
}

ul.lst-kix_list_12-8 {
  list-style-type: none;
}

ul.lst-kix_list_12-7 {
  list-style-type: none;
}

.lst-kix_list_7-0 > li:before {
  content: "" counter(lst-ctn-kix_list_7-0, upper-latin) ". ";
}

.lst-kix_list_2-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_2-4 > li:before {
  content: "o  ";
}

.lst-kix_list_2-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_7-1 > li:before {
  content: "" counter(lst-ctn-kix_list_7-1, lower-latin) ". ";
}

.lst-kix_list_7-5 > li:before {
  content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". ";
}

.lst-kix_list_7-3 > li:before {
  content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
}

ol.lst-kix_list_11-1.start {
  counter-reset: lst-ctn-kix_list_11-1 0;
}

.lst-kix_list_10-0 > li:before {
  content: "" counter(lst-ctn-kix_list_10-0, decimal) ". ";
}

.lst-kix_list_10-6 > li {
  counter-increment: lst-ctn-kix_list_10-6;
}

.lst-kix_list_11-6 > li {
  counter-increment: lst-ctn-kix_list_11-6;
}

.lst-kix_list_8-7 > li {
  counter-increment: lst-ctn-kix_list_8-7;
}

.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}

.lst-kix_list_7-7 > li:before {
  content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". ";
}

ol.lst-kix_list_10-0.start {
  counter-reset: lst-ctn-kix_list_10-0 0;
}

ol.lst-kix_list_8-1.start {
  counter-reset: lst-ctn-kix_list_8-1 0;
}

.lst-kix_list_7-5 > li {
  counter-increment: lst-ctn-kix_list_7-5;
}

.lst-kix_list_11-4 > li {
  counter-increment: lst-ctn-kix_list_11-4;
}

.lst-kix_list_10-4 > li:before {
  content: "(" counter(lst-ctn-kix_list_10-4, lower-roman) ") ";
}

.lst-kix_list_10-8 > li:before {
  content: "" counter(lst-ctn-kix_list_10-8, lower-latin) ") ";
}

.lst-kix_list_4-0 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_10-2 > li:before {
  content: "(" counter(lst-ctn-kix_list_10-2, lower-roman) ") ";
}

ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}

.lst-kix_list_4-4 > li:before {
  content: "o  ";
}

.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}

.lst-kix_list_4-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_4-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_9-3 > li:before {
  content: "\0025cf  ";
}

ol.lst-kix_list_7-0.start {
  counter-reset: lst-ctn-kix_list_7-0 0;
}

.lst-kix_list_10-8 > li {
  counter-increment: lst-ctn-kix_list_10-8;
}

.lst-kix_list_10-6 > li:before {
  content: "(" counter(lst-ctn-kix_list_10-6, decimal) ") ";
}

.lst-kix_list_9-1 > li:before {
  content: "o  ";
}

.lst-kix_list_9-7 > li:before {
  content: "o  ";
}

.lst-kix_list_11-4 > li:before {
  content: "" counter(lst-ctn-kix_list_11-4, lower-latin) ". ";
}

.lst-kix_list_12-4 > li:before {
  content: "o  ";
}

.lst-kix_list_9-5 > li:before {
  content: "\0025aa  ";
}

ul.lst-kix_list_6-6 {
  list-style-type: none;
}

ul.lst-kix_list_6-7 {
  list-style-type: none;
}

ul.lst-kix_list_6-4 {
  list-style-type: none;
}

ul.lst-kix_list_6-5 {
  list-style-type: none;
}

ul.lst-kix_list_6-8 {
  list-style-type: none;
}

.lst-kix_list_12-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_7-4 > li {
  counter-increment: lst-ctn-kix_list_7-4;
}

.lst-kix_list_11-6 > li:before {
  content: "" counter(lst-ctn-kix_list_11-6, decimal) ". ";
}

.lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, upper-latin) ". ";
}

ul.lst-kix_list_6-2 {
  list-style-type: none;
}

.lst-kix_list_11-8 > li:before {
  content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". ";
}

ul.lst-kix_list_6-3 {
  list-style-type: none;
}

ol.lst-kix_list_11-3.start {
  counter-reset: lst-ctn-kix_list_11-3 0;
}

.lst-kix_list_1-2 > li:before {
  content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". ";
}

ul.lst-kix_list_6-0 {
  list-style-type: none;
}

.lst-kix_list_12-0 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_list_6-1 {
  list-style-type: none;
}

ol.lst-kix_list_8-4.start {
  counter-reset: lst-ctn-kix_list_8-4 0;
}

.lst-kix_list_1-4 > li:before {
  content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". ";
}

.lst-kix_list_10-1 > li {
  counter-increment: lst-ctn-kix_list_10-1;
}

.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}

.lst-kix_list_8-8 > li {
  counter-increment: lst-ctn-kix_list_8-8;
}

.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}

.lst-kix_list_1-6 > li:before {
  content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

.lst-kix_list_10-7 > li {
  counter-increment: lst-ctn-kix_list_10-7;
}

.lst-kix_list_2-0 > li:before {
  content: "\0025cf  ";
}

ol.lst-kix_list_8-3.start {
  counter-reset: lst-ctn-kix_list_8-3 0;
}

.lst-kix_list_12-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_list_11-5 > li {
  counter-increment: lst-ctn-kix_list_11-5;
}

ol.lst-kix_list_11-4.start {
  counter-reset: lst-ctn-kix_list_11-4 0;
}

.lst-kix_list_1-8 > li:before {
  content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
}

.lst-kix_list_2-2 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_12-8 > li:before {
  content: "\0025aa  ";
}

.lst-kix_list_8-2 > li {
  counter-increment: lst-ctn-kix_list_8-2;
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c26 {
  border-right-style: solid;
  padding: 2.9pt 2.9pt 2.9pt 2.9pt;
  border-bottom-color: #bfbfbf;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #bfbfbf;
  vertical-align: top;
  border-right-color: #bfbfbf;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 311.6pt;
  border-top-color: #bfbfbf;
  border-bottom-style: solid;
}

.c32 {
  border-right-style: solid;
  padding: 2.9pt 2.9pt 2.9pt 2.9pt;
  border-bottom-color: #bfbfbf;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #bfbfbf;
  vertical-align: top;
  border-right-color: #bfbfbf;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 155.8pt;
  border-top-color: #bfbfbf;
  border-bottom-style: solid;
}

.c10 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c2 {
  margin-left: 0pt;
  padding-top: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
  padding-bottom: 12pt;
  line-height: 1.5em;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c16 {
  -webkit-text-decoration-skip: none;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 1em;
  
  font-style: normal;
}

.c13 {
  margin-left: 36pt;
  padding-top: 0pt;
  text-indent: -18pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}

.c27 {
  margin-left: 54pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 6pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c18 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}

.c19 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c6 {
  margin-left: 18pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c28 {
  margin-left: 72pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 6pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c5 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c9 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 1em;
  
  font-style: normal;
}

.c3 {
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 1em;
  font-style: normal;
}

.c4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 11pt;
}

.c29 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: center;
  height: 11pt;
}

.c35 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c7 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c25 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c37 {
  padding-top: 14pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c39 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c40 {
  padding-top: 0pt;
  padding-bottom: 6pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c31 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.c17 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c33 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c21 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  font-style: italic;
  text-decoration: underline;
}

.c24 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}

.c36 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 1em;
  
}

.c15 {
  margin-left: -2.9pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}

.c43 {
  margin-left: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
}

.c42 {
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.c20 {
  margin-left: 36pt;
  padding-left: 0pt;
}

.c11 {
  text-decoration: inherit;
}

.c8 {
  font-weight: 700;
}

.c44 {
  list-style-position: inside;
  text-indent: 18pt;
}

.c23 {
  margin-left: 18pt;
  padding-left: 0pt;
}

.c1 {
  padding: 0;
  margin: 0;
}

.c30 {
  font-weight: 400;
}

.c41 {
  page-break-after: avoid;
}

.c38 {
  margin-left: 36pt;
}

.c12 {
  text-indent: 36pt;
}

.c22 {
  font-style: italic;
}

.c14 {
}

.c34 {
  font-weight: 700;
}

.c0 {
  height: 0pt;
}

.title {
  padding-top: 12pt;
  font-weight: 700;
  font-size: 1em;
  padding-bottom: 12pt;
  font-family: "Cubano";
  line-height: 1.5em;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.subtitle {
  padding-top: 0pt;
  font-weight: 700;
  font-size: 1em;
  padding-bottom: 12pt;
  font-family: "Cubano";
  line-height: 1.5em;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  font-size: 1em;
  
}

p {
  margin: 0;
  font-size: 1em;
  
}

h1 {
  padding-top: 0pt;
  font-weight: 700;
  font-size: 1em;
  padding-bottom: 12pt;
  font-family: "Cubano";
  line-height: 1.5em;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

h2 {
  padding-top: 0pt;
  font-size: 1em;
  padding-bottom: 12pt;
  font-family: "Cubano";
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

h3 {
  padding-top: 0pt;
  font-size: 1em;
  padding-bottom: 12pt;
  font-family: "Cubano";
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 0pt;
  font-size: 1em;
  padding-bottom: 12pt;
  font-family: "Cubano";
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

h5 {
  padding-top: 0pt;
  font-size: 1em;
  padding-bottom: 12pt;
  font-family: "Cubano";
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 0pt;
  font-size: 1em;
  padding-bottom: 12pt;
  font-family: "Cubano";
  line-height: 1.5em;
  orphans: 2;
  widows: 2;
  text-align: left;
}
</style>
