import log from 'loglevel'

// Enable log to be accessible on window
// so on production you can run
// log.setLevel('trace') to see logs even if disabled by default
if (typeof window !== 'undefined') {
  window.log = log
}

const logLevels = [
  'trace',
  'debug',
  'info',
  'warn',
  'error'
]

if (logLevels.includes(process.env.LOG_LEVEL)) {
  log.setDefaultLevel(process.env.LOG_LEVEL)
} else if (process.env.NODE_ENV === 'production') {
  log.setDefaultLevel('warn')
} else {
  log.setDefaultLevel('trace')
}


export {log}
