<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex';
import useCountdown from '/src/utility/useCountdown'
import useShopConfig from '/src/utility/useShopConfig'
import useNow from '/src/utility/useNow'
import ConnectWallet from '/src/components/common/ConnectWallet';
import { computed } from '@vue/reactivity';
import Button from '@/components/ui/Button.vue';
import config from '/src/config'

let isLoading = ref(true)
let root = ref(null)

let load = () => {
  if (root.value) {
    let imgs = [...root.value.querySelectorAll('img')]
    if (imgs.every(img => img.complete)) {
      isLoading.value = false
    }
  }
}

let { shopConfig, isLoading: isLoadingShopConfig } = useShopConfig()

let wlDate = computed(() => new Date(parseInt(shopConfig.value?.ethStartTime || 0, 10)))
let pubDate = computed(() => new Date(parseInt(shopConfig.value?.publicStartTime || 0, 10)))

let {
  formattedDaysLeft,
  formattedHoursLeft,
  formattedMinutesLeft,
  formattedSecondsLeft,
  timeLeft
} = useCountdown({ endDate: wlDate });
let {
  formattedDaysLeft: pubFormattedDaysLeft,
  formattedHoursLeft: pubFormattedHoursLeft,
  formattedMinutesLeft: pubFormattedMinutesLeft,
  formattedSecondsLeft: pubFormattedSecondsLeft,
  timeLeft: pubTimeLeft,
} = useCountdown({ endDate: pubDate });

let now = useNow()
// let now = ref(new Date(2022, 3, 20))

const store = useStore();

const isReady = computed(() => {
  return store.state.mint.shopConfig != null;
});

const sold = computed(() => {
  if (!isReady.value) return 0;
  return store.state.mint.soldCount;
});

const totalCap = computed(() => {
  if (!isReady.value) return 1;
  return store.state.mint.shopConfig.totalCap.toNumber();
});

let soldOut = computed(() => sold.value >= totalCap.value)

let status = computed(() => {
  let nowTime = now.value.getTime()
  if (soldOut.value) {
    return 'MINT_SOLD_OUT'
  }
  if (parseInt(shopConfig.value?.ethStartTime, 10) > nowTime) {
    return 'WHITELIST_MINT_COMING'
  }
  if (parseInt(shopConfig.value?.ethStartTime, 10) < nowTime && parseInt(shopConfig.value?.publicStartTime, 10) > nowTime ) {
    return 'MINT_COMING'
  }
  if (parseInt(shopConfig.value?.publicEndTime, 10) <= nowTime) {
    return 'MINT_COMPLETE'
  }
  if (parseInt(shopConfig.value?.ethStartTime, 10) <= nowTime && parseInt(shopConfig.value.publicEndTime, 10) >= nowTime) {
    return 'MINT_LIVE'
  }
  return 'OTHER'
})
</script>

<template>
  <div class="splash-area" ref="root">
    <div class="container">
      <div class="moon" :class="{ animate: !isLoading }">
        <div class="wrapper" :class="{ animate: !isLoading }">
          <img
            class="logo"
            src="https://lunartics.s3.amazonaws.com/assets/lunartics-logo.webp"
            @load="load"
          />
          <template v-if="true">
            <p class="subtext" :class="{ animate: !isLoading }">
              <!-- A fantastically playful moon <br /> shot destined to become the next <br /> great <span>animated series</span> -->
              you can't have a big brain,
              <br />if you got a tiny head.
            </p>
            <Button
              class="button-mint"
              as="a"
              :href="config.openseaUrl"
              target="_blank"
              color="#1D76DE"
            >BUY ON OPENSEA</Button>
          </template>
          <template v-if="false">
            <p class="subtext" :class="{ animate: !isLoading }">
              <!-- A fantastically playful moon <br /> shot destined to become the next <br /> great <span>animated series</span> -->
              you can't have a big brain,
              <br />if you got a tiny head.
            </p>
            <p
              class="subtext2"
              :class="{ animate: !isLoading }"
            >An animated series on the blockchain.</p>
          </template>
        </div>

        <img
          class="moon-img"
          :class="{ animate: !isLoading }"
          src="https://lunartics.s3.amazonaws.com/assets/full-moon.webp"
          @load="load"
        />
      </div>
      <div class="connect">
        <!-- <ConnectWallet /> -->
      </div>
    </div>
    <div class="character-container">
      <div class="character-images">
        <div class="robot" :class="{ animate: !isLoading }">
          <img
            class="floating"
            src="https://lunartics.s3.amazonaws.com/assets/characters/robot.webp"
            @load="load"
          />
        </div>
        <div class="viking" :class="{ animate: !isLoading }">
          <img
            class="floating"
            src="https://lunartics.s3.amazonaws.com/assets/characters/viking.webp"
            @load="load"
          />
        </div>
        <div class="lucha" :class="{ animate: !isLoading }">
          <div class="lucha-in" :class="{ animate: !isLoading }">
            <img
              class="floating"
              src="https://lunartics.s3.amazonaws.com/assets/characters/lucha.webp"
              @load="load"
            />
          </div>
        </div>
        <div class="hawaii" :class="{ animate: !isLoading }">
          <img class="floating" src="/images/characters/hawaii.webp" @load="load" />
        </div>
        <div class="hippie" :class="{ animate: !isLoading }">
          <img
            class="floating"
            src="https://lunartics.s3.amazonaws.com/assets/characters/hippie.webp"
            @load="load"
          />
        </div>
      </div>
    </div>

    <div class="street-bg">
      <img
        class="large"
        src="https://lunartics.s3.amazonaws.com/assets/4080-street-bg.webp"
        @load="load"
      />
      <img
        class="medium"
        src="https://lunartics.s3.amazonaws.com/assets/2048-street-bg.webp"
        @load="load"
      />
    </div>
    <div class="stars">
      <img
        src="https://lunartics.s3.amazonaws.com/assets/stars.webp"
        style="display: none;"
        @load="load"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.splash-area {
  min-height: 100vh;
  background: linear-gradient(black, #2c3666);
  position: relative;

  --easing: 1s forwards cubic-bezier(0, 1.36, 0.24, 1);

  .character-container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    overflow-x: clip;

    .character-images {
      max-width: 1200px;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 20;

      .robot,
      .viking,
      .lucha .lucha-in,
      .hawaii,
      .hippie {
        opacity: 0;
        transition: ease-in-out opacity 0.5s;
        transform: translateY(-3000px);
        &.animate {
          opacity: 1;
        }
      }

      .floating {
        animation: floating 2s ease-in-out infinite;
        animation-delay: var(--float-delay, 0ms);
      }

      .robot {
        position: absolute;
        top: -10px;
        left: 0;
        height: auto;
        img {
          max-width: 300px;
          width: 100%;
          object-fit: contain;
        }

        --float-delay: 400ms;

        &.animate {
          animation: robot-in var(--easing);
          animation-delay: 400ms;
        }
      }

      .viking {
        position: absolute;
        left: 0;
        bottom: 30%;
        z-index: 10;

        img {
          width: 220px;
          height: auto;
        }

        --float-delay: 100ms;

        &.animate {
          animation: viking-in var(--easing);
          animation-delay: 100ms;
        }
      }

      .lucha {
        position: absolute;
        top: 85%;
        left: 50%;
        transform: translate(-50%, -50%);
        .animate {
          animation: lucha-in var(--easing);
          animation-delay: 700ms;
        }

        --float-delay: 700ms;

        img {
          object-fit: contain;
          max-height: 30vh;
          width: 360px;
          height: auto;
        }
      }

      .hawaii {
        position: absolute;
        right: -40px;
        bottom: 6%;
        transform: translate(800px, 800px);

        --float-delay: 900ms;

        img {
          width: 320px;
          height: auto;
        }

        &.animate {
          animation: hawaii-in var(--easing);
          animation-delay: 900ms;
        }
      }

      .hippie {
        position: absolute;
        right: 0;
        top: 10%;
        img {
          width: 320px;
          height: auto;
          object-fit: contain;
        }

        --float-delay: 200ms;

        &.animate {
          animation: hippie-in var(--easing);
          animation-delay: 200ms;
        }
      }
    }
  }

  .container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-height: 700px) {
      top: -56px;
    }

    .connect {
      margin-top: -3%;
      width: 100%;
    }

    .moon {
      width: 100%;
      height: calc(100% - 60px);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px;
      overflow: hidden;

      .wrapper {
        position: absolute;
        z-index: 100;
        opacity: 0;
        text-align: center;

        &.animate {
          opacity: 1;
          animation: moon-in var(--easing);
        }

        .logo {
          width: 100%;
          /* max-width: 600px; */
          max-width: 718px;
          height: auto;
          max-height: 30vh;
        }

        .subtext,
        .subtext2 {
          font-family: "Cubano";
          font-style: normal;
          font-size: 22px;
          text-align: center;
          color: #1e2c63;
          opacity: 0;
          transition: ease-in-out opacity 0.5s;
          margin-bottom: 16px;

          &.animate {
            opacity: 1;
            transition-delay: 700ms;
          }

          span {
            font-family: "Cubano";
            color: #f80057;
          }
        }

        .subtext2 {
          font-size: 16px;
        }

        .subtext.countdown-title {
          font-size: 37px;
          margin-bottom: 0px;
        }

        .subtext.countdown {
          font-size: 41px;
        }

        .countdown-label {
          font-size: 0.8em;
        }
      }

      .moon-img {
        max-width: 630px;
        width: 100%;
        height: auto;
        padding: 10px;
        z-index: 2;

        opacity: 0;

        &.animate {
          opacity: 1;
          animation: moon-in var(--easing);
        }
      }
    }
  }

  .button-mint {
    display: block;
    max-width: 258px;
    margin: 22px auto;
  }

  @media (max-height: 780px) {
    .splash-area .container .moon .wrapper .logo {
    }
  }

  .stars {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    /* background-image: url("https://lunartics.s3.amazonaws.com/assets/stars.webp"); */
    background-image: url("/images/stars-bright.webp");
    background-size: cover;
    mask: url("/images/star-mask.png");
    mask-position: 0px 50%;
    animation: twinkle 48s linear infinite;
    mask-size: 900px;
  }

  @keyframes twinkle {
    from {
      mask-position: 0px 50%;
    }
    to {
      mask-position: 900px 50%;
    }
  }

  .street-bg {
    max-width: 100vw;
    height: auto;
    object-fit: contain;
    position: absolute;
    bottom: 0;
    margin-bottom: -5px;
    z-index: 10;
    .large {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    .medium {
      width: 100%;
      height: auto;
      display: none;
    }
  }
}

@media (max-width: 2048px) {
  .splash-area {
    .street-bg {
      .large {
        display: none;
      }
      .medium {
        display: initial;
      }
    }
  }
}

@media (max-width: 840px) {
  .splash-area .character-container .character-images {
    .robot {
      max-width: 35vw;
    }

    .hippie {
      top: 15%;
      img {
        max-width: 35vw;
      }
    }

    .lucha {
      img {
        max-width: 50vw;
      }
    }
    .viking {
      display: none;
    }

    .hawaii {
      display: none;
    }
  }
}

@media (max-height: 680px) {
  .viking {
    display: none;
  }
  .hawaii {
    display: none;
  }
}

@media (max-width: 566px) {
  .splash-area .container .moon .wrapper .subtext {
    font-size: 15px;
    &.countdown-title {
      font-size: 20px;
    }
    &.countdown {
      font-size: 23px;
    }
  }
  .splash-area .container .moon .wrapper .subtext2 {
    font-size: 13px;
  }
}

@media (max-width: 430px) {
  .splash-area .container .moon .wrapper .subtext {
    font-size: 13px;
  }
  .splash-area .container .moon .wrapper .subtext2 {
    font-size: 12px;
    max-width: 200px;
    margin: auto;
  }
}

@keyframes viking-in {
  0% {
    transform: translate(-800px, 800px);
  }
  20% {
    transform: translate(-800px, 800px);
  }
  100% {
    transform: translate(0, 0);
    visibility: visible;
  }
}
@keyframes robot-in {
  0% {
    transform: translate(-800px, -800px);
  }
  20% {
    transform: translate(-800px, -800px);
  }
  100% {
    transform: translate(0, 0);
    visibility: visible;
  }
}
@keyframes lucha-in {
  0% {
    transform: translate(0px, 800px);
  }
  20% {
    transform: translate(0px, 800px);
  }
  100% {
    transform: translate(0, 0);
    visibility: visible;
  }
}
@keyframes hawaii-in {
  0% {
    transform: translate(800px, 800px);
  }
  20% {
    transform: translate(800px, 800px);
  }
  100% {
    transform: translate(0, 0);
    visibility: visible;
  }
}
@keyframes hippie-in {
  0% {
    transform: translate(800px, -800px);
  }
  20% {
    transform: translate(800px, -800px);
  }
  100% {
    transform: translate(0, 0);
    visibility: visible;
  }
}
@keyframes moon-in {
  0% {
    transform: translate(0px, 800px);
  }
  20% {
    transform: translate(0px, 800px);
  }
  100% {
    transform: translate(0, 0);
    visibility: visible;
  }
}
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 8px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
</style>
