<template>
  <a :href="`https://opensea.io/assets/${clone.collectionAddress}/${clone.tokenId}`" target="_blank">
    <div class="container">
      <div class="img">
        <img class="skeleton" :src="imgSrc" />
      </div>
      <p class="text">{{ name }}</p>
    </div>
  </a>
</template>
<script>
import { computed } from 'vue';

export default {
  props: {
    clone: Object
  },

  setup(props) {
    const name = computed(() => {
      return `LUNA #${props.clone.tokenId}`;
    });

    let imgSrc = computed(() => props.clone.metadata?.image || '/images/pre-reveal-sq.webp')
    return { name, imgSrc };
  }
};
</script>
<style scoped lang="scss">
a {
  text-decoration: none;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all ease 150ms;
  cursor: pointer;

  .img {
    width: 100%;
    margin-bottom: 12px;

    .skeleton {
      height: auto;
      width: 100%;
      object-fit: contain;
      border-radius: 20px;
    }
  }

  .text {
    text-shadow: 0px 0px 10px #fff;
    text-transform: uppercase;
    color: #23315b;
    font-weight: 700;
    font-size: 22px;
  }

  &:hover {
    transform: scale(1.05);
    .text {
      color: #f80057;
    }
  }
}
</style>
