<template>
  <Ethereum :callback="() => {}" />
  <transition name="fade" mode="out-in">
    <div class="wallet-modal-container" v-if="alert.showWalletModal">
      <WalletModal @exit="hideWalletModalDisplay" />
    </div>
  </transition>

  <div class="navBar" :class="navBarStyles">
    <div class="container">
      <router-link to="/" class="logo-wrapper">
        <img
          v-if="!isHome && !hideLogo"
          src="https://lunartics.s3.amazonaws.com/assets/lunartics-logo.webp"
          alt="Header Logo"
          class="header-logo"
        />
      </router-link>
      <div class="menuLinks" v-if="false">
        <a class="link link-text" v-smooth-scroll href="#trailer">TRAILER</a>
        <a class="link link-text" v-smooth-scroll href="#roadmap">ROADMAP</a>
        <a class="link link-text" v-smooth-scroll href="#faq">FAQ</a>
        <a class="link link-text" v-smooth-scroll href="#team">TEAM</a>
      </div>

      <div class="menuIcon" @click="showMobileMenu">
        <MobileMenuIcon />
      </div>

      <teleport to="body">
        <MobileMenu
          :isVisible="mobileMenuIsVisible"
          :isHome="isHome"
          @toggleVisibility="mobileMenuIsVisible = false"
          @showWalletModal="showWalletModalDisplay"
        />
      </teleport>

      <div class="right" v-if="!isHome">
        <template v-if="!transparentBg">
          <div class="link" @click="openUrl('https://twitter.com/PlayImpostors')">
            <TwitterIcon />
          </div>
          <div class="link" @click="openUrl('https://discord.gg/ghv8vjj52D')">
            <DiscordIcon />
          </div>
        </template>

        <div class="button-container">
          <div
            v-if="ethereum.address"
            class="address"
            :class="!showDropdown ? 'dropdown-hidden' : ''"
            @click.stop="toggleDropdown"
          >
            <div class="label">
              <span>{{ profileAddressLabel }}</span>
              <DownArrowIcon
                class="svg-icon"
                :class="showDropdown ? 'flip' : ''"
              />
            </div>
            <div class="profile-dropdown" v-if="showDropdown">
              <div
                class="dropdown-item"
                @click="routeTo(`/mint`)"
              >
                <span class="item-text">Mint</span>
              </div>
              <div
                class="dropdown-item"
                @click="routeTo(`/my-items/${ethereum.address}`)"
              >
                <span class="item-text">My Items</span>
              </div>
              <div
                class="dropdown-item"
                @click="disconnect()"
              >
                <span class="item-text">Disconnect</span>
              </div>
            </div>
          </div>

          <!-- The user is not connected to a signing provider. -->
          <div
            button
            class="address"
            v-if="(!ethereum.address && !isMobile) || buttonMsg"
            @click="handleButtonClicked"
          >
            <div class="label wallet">
              <span>{{ walletPrompt }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
// Component imports.
import WalletModal from '../layout/WalletModal.vue';
import MobileMenu from './MobileMenu.vue';

// Icon imports.
import { useTask } from 'vue-concurrency';
import { ethers } from 'ethers';
import store from '@/store';
import Ethereum from '/src/components/common/Ethereum.vue';
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';
import MobileMenuIcon from '/src/components/icons/MobileMenuIcon';
import DownArrowIcon from '/src/components/icons/DownArrowIcon.vue';

// Set up the default header component.
export default {
  name: 'Header',
  components: {
    DiscordIcon,
    TwitterIcon,
    WalletModal,
    MobileMenu,
    MobileMenuIcon,
    DownArrowIcon,
    Ethereum
  },
  props: {
    isHome: {
      type: Boolean
    },
    hideLogo: {
      type: Boolean
    },
    buttonMsg: {
      type: String
    },
    transparentBg: {
      type: Boolean
    }
  },
  data() {
    return {
      showDropdown: false,
      mobileMenuIsVisible: false,
      now: new Date()
    };
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      ethereum: state => state.ethers,
      publicEndTime: state => state.mint?.shopConfig?.publicEndTime
    }),

    walletPrompt() {
      if (this.buttonMsg) {
        return this.buttonMsg;
      } else if (!this.ethereum.address) {
        return 'CONNECT WALLET';
      } else {
        return '...';
      }
    },

    profileAddressLabel() {
      let connectedAddress = this.ethereum.address;
      if (connectedAddress) {
        const shortenedAddress =
          connectedAddress.substring(0, 6) +
          '...' +
          connectedAddress.substring(connectedAddress.length - 4);
        return shortenedAddress;

        // If the user has not connected to a signing address, return no label.
      } else {
        return '';
      }
    },

    mintIsOver() {
      return this.now >= this.publicEndTime;
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    navBarStyles() {
      return this.transparentBg ? '' : 'color-bg';
    },

    showLogo() {
      return !this.isHome && !this.hideLogo;
    }
  },

  mounted() {
    document.addEventListener('click', this.close);

    setInterval(() => {
      this.now = new Date();
    }, 1000);
  },

  onBeforeUnmount() {
    document.removeEventListener('click', this.close);
  },

  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      showWalletModal: 'alert/showWalletModal',
      hideWalletModal: 'alert/hideWalletModal'
    }),
    ...mapActions('ethers', ['connectWallet', 'disconnectWallet']),

    // TODO

    handleButtonClicked() {
      if (this.buttonMsg === 'MINT') {
        this.routeTo('/mint');
      } else {
        this.showWalletModalDisplay();
      }
    },

    showWalletModalDisplay() {
      this.showWalletModal();
    },
    hideWalletModalDisplay() {
      this.hideWalletModal();
    },

    // Connect to an Ethereum wallet.
    connect() {
      this.connectWallet();
    },

    // Disconnect from an Ethereum wallet.
    disconnect() {
      this.$store.commit('disconnectWalletConnectStore');
      store.dispatch('ethers/disconnectWallet');
    },

    // Toggle the dropdown menu display.
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    // Close the dropdown menu.
    close(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },

    // Route to a different path.
    routeTo(path) {
      this.$router.push(path);
    },

    openUrl(url) {
      window.open(url, '_blank');
    },

    showMobileMenu() {
      // Disable scrolling on window.
      window.onscroll = function () {
        window.scrollTo(0, 0);
      };
      this.mobileMenuIsVisible = true;
    }
  },

  // Clear the application alert when routes change.
  watch: {
    $route(to, from) {
      // this.clearAlert();
    }
  }
};
</script>

<style scoped lang="scss">
.color-bg {
  background: #0e173a;
}
.navBar {
  //background-color: black;
  padding: 12px;
  position: absolute;
  z-index: 100;
  width: 100%;
  box-sizing: border-box;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    padding-right: 250px;
    position: relative;

    .logo-wrapper {
      width: 258px;
      height: auto;
      min-height: 80px;
      .header-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;

        transition: all 300ms ease;

        &:active {
          transform: scale(0.95);
        }
      }
    }

    .spacer {
      margin-right: auto;
    }

    .menuLinks {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 10px;
    }

    .link-text {
      font-family: 'Cubano';
      font-style: normal;
      font-weight: 400;
      font-size: 22px !important;
    }

    .link {
      display: flex;
      padding: 10px 14px;
      align-items: center;
      border-radius: 10px;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      border: none;
      height: fit-content;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }
    }

    .line {
      border-bottom: 2px solid white;
      width: 100px;
    }

    .menuIcon {
      height: 50px;
      width: 50px;
      color: white;
      display: none;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .connect {
      box-shadow: inset 0 0 0 1px rgb(128, 128, 128);
    }

    .right {
      display: flex;
      gap: 10px;
    }
    .button-container {
      position: absolute;
      right: 20px;
      top: 9px;
      width: 240px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      // .dropdown-hidden {
      //   transition: all ease 400ms;
      //   &:hover {
      //     // opacity: 0.8;
      //     // transform: scale(0.97);
      //     filter: brightness(0.8);
      //   }

      //   &:active {
      //     // opacity: 1;
      //     // transform: scale(0.97);
      //     filter: brightness(0.7);
      //   }
      // }

      .address {
        padding: 15px 20px;
        text-decoration: none;
        margin-left: 20px;
        min-width: fit-content;
        font-size: 22px;
        color: white;
        font-style: normal;
        font-weight: 400;
        background: #f80057;
        box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.44),
          inset 0px -5px 0px rgba(0, 0, 0, 0.25);
        border-radius: 22px;
        transition: all ease 200ms;
        cursor: pointer;

        &:hover {
          box-shadow: inset 0px 0px 0px rgba(255, 255, 255, 0.44),
            inset 0px -9px 0px rgba(0, 0, 0, 0.25);
        }

        &:active {
          box-shadow: inset 0px 0px 0px rgba(255, 255, 255, 0.44),
            inset 0px 0px 0px rgba(0, 0, 0, 0.25);
        }
        .label {
          display: flex;
          align-items: center;
          gap: 20px;
          padding-left: 12px;
          padding-bottom: 6px;
          white-space: nowrap;
          span {
            font-family: 'Cubano';
            font-weight: 400;
          }

          .svg-icon {
            margin-top: auto;
            margin-bottom: auto;
            height: 10px;
            transition: all ease 200ms;
          }

          .flip {
            transform: rotateZ(180deg);
          }
        }

        .wallet {
          padding-left: 0;
        }
      }

      .profile-dropdown {
        font-size: 14px;
        z-index: 10;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .dropdown-item {
          padding: 16px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 15px;
          transition: all 0.2s ease;
          width: 100%;
          display: flex;
          justify-content: center;

          &:hover {
            background: rgba(0, 0, 0, 0.3);
          }

          &:active {
            background: rgba(0, 0, 0, 0.4);
          }

          .item-text {
            font-family: 'Cubano';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            color: white;
            align-items: flex-start;
            width: 100%;
          }
        }
      }
    }
  }
}

.homeNavBar {
  //background-color: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
}

.wallet-modal-container {
  position: fixed;
  z-index: 9999;
}

.header {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;

  padding: 5px 30px;
  justify-items: center;
  position: relative;
  z-index: 99;
}

.header > img {
  height: 40px;
  grid-column-start: 2;
  transition: ease 0.2s;
}

.header > img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.header > img:active {
  transform: scale(0.95);
}

.wallet_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 50px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.wallet_button:hover {
  cursor: pointer;
  background: var(--background-color);
  color: var(--text-color);
}

.wallet_button:active {
  transform: scale(0.9);
}

.profile_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 0px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 40px;
  width: 160px;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.profile_button:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.profile_button:active {
  background-color: rgba(var(--purple-accent-rgb), 0.2);
}

.profile_button > p {
  margin-right: 10px;
  margin-left: 10px;
  color: var(--text-color);
  font-size: 14px;
}

.svg-icon {
  margin-top: -5px;
  margin-left: -5px;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}

// Animations
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 1s ease;
  transition-delay: 0.5s;
}

.slide-enter-from
// .slide-leave-to
{
  transform: translateY(-100px);
}

@media (max-width: 1100px) {
  .navBar .container {
    justify-content: space-between;
    padding-right: 0px;

    .right {
      display: none;
    }
  }
  .navBar .container .link {
    display: none;
  }

  .navBar .container .address {
    display: none;
  }

  .navBar .container .menuIcon {
    display: flex;
    justify-self: flex-end;
  }

  .navBar .container .logo-wrapper {
    width: 200px;
  }

  .navBar .container .logo {
    display: initial;
    width: fit-content;
    img {
      max-height: 49px;
    }
  }
}
</style>
