import {computed, onBeforeMount} from 'vue'
import { useTask } from 'vue-concurrency';
import { useStore } from 'vuex';
// import initializeConfig from '/src/initialize-config';

export default function useEthereum(callback) {
  let store = useStore()
  let ethereumReference = computed(() => store.state.ethers);

  onBeforeMount(() => {
    let getLaunchpadItemsTask = useTask(function*(signal, callback) {
      // this.config = initializeConfig();
      if (!ethereumReference.value?.provider) {
        yield store.dispatch('ethers/initialize', async () => {
          await callback();
        });
      } else {
        yield callback();
      }
    });

    getLaunchpadItemsTask.perform(callback);
  })
}
