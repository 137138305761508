<template>
  <component :is="as" class="button" :style="buttonColor">
    <slot></slot>
  </component>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    color: {
      type: String,
      default: '#f80057'
    },
    as: {
      type: String,
      default: 'button'
    }
  },

  setup(props) {
    const buttonColor = computed(() => {
      return { backgroundColor: props.color };
    });

    return {
      buttonColor
    };
  }
};
</script>

<style scoped>
.button {
  cursor: pointer;
  font-family: 'Cubano';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  padding: 15px 20px;
  border-radius: 22px;
  color: white;
  background: #f80057;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.44),
    inset 0px -5px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: 18px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  transition: all ease 200ms;
}

.button:hover {
  filter: brightness(0.8);
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.44),
    inset 0px 1px 0px rgba(0, 0, 0, 0.25);
}

.button:active {
  filter: brightness(1);
}
</style>
