<script setup>
import RocketA from '@/components/common/RocketA.vue'

// separates last word.
let titleSeperator = (str) => str.split(/\s([^\s]*)$/).filter(str => str !== '')

let sections = [
  {
    number: '01',
    title: 'Prepare to Launch!',
    text: ['After 50 plus years of attempted failures and a mere 10,000 “Nice Fu&#ing Tries” (NFTs), the Lunartics are preparing to launch in partnership with SuperFarm! ']
  },
  {
    number: '02',
    class: 'blast-off',
    title: 'Blast off!',
    text: ['<span class="text-emphasis">Launch The Lunartics animated series!</span> Release the first episode of the Lunartics’ most futile and embarrassing missions filled with nostalgic yet timely misfortunes that entertainment-starved humans won’t be able to get enough of.']
  },
  {
    number: '03',
    class: 'mission',
    title: 'Mission Control!',
    text: ['<span class="text-emphasis">Write a bio or backstory for your character — and maybe your Lunartic will pop up in a mission!</span> Two heads are better than one, especially when they’re tiny, so if you think you have a better way to get to the moon and we use it in a future episode, you get rewarded.']
  },
  {
    number: '04',
    class: 'orbital',
    title: 'Totally Orbital!',
    text: [
      ' Your Lunartics NFT is your ticket into the inner workings of making an animated series. Go behind the scenes with two industry insiders. ',
      'Rub shoulders with an exclusive community of animators, writers, directors, voice actors, and producers. Be a part of the next big bang when blockchain technology converges with the world of entertainment!']
  },
].map((section) => ({
  ...section,
  titleParts: titleSeperator(section.title).map((str, i, arr) => ({
    text: str,
    class: i === arr.length - 1 ? 'text-emphasis' : ''
  }))
}))

</script>
<template>
  <div class="roadmap">
    <div class="stars" />
    <div class="container">
      <h1>
        <span>
          <span>ROAD</span>
          <span class="text-emphasis">M</span>
        </span>
        <RocketA />
        <span class="text-emphasis">P</span>
      </h1>
      <div v-for="(item, index) in sections" :key="index" class="map-section" :class="item.class">
        <div class="map-number">{{ item.number }}</div>
        <div class="map-content">
          <h2 class="map-title">
            <template v-for="(part, index) in item.titleParts" :key="index">
              <span :class="part.class">{{part.text}}</span>
              {{' '}}
            </template>
          </h2>
          <p v-for="(text, index) in item.text" :key="index" class="map-text" v-html="text"></p>
        </div>
      </div>
      <div class="roadmap-graphic">
        <div class="svg-crop">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1680"
            viewBox="0 0 792 1680"
            fill="none"
            class="roadmap-path"
            preserveAspectRatio="none"
          >
            <path
              d="M686 4C634 92.1667 618.013 220.703 365 230.5C197.9998 242 -95.4531 312.479 99.9998 506C182 637 470 511 599.5 575C735.016 641.973 758.5 1003 338 1004C256.333 999.333 110.2 1047.2 179 1276"
              stroke="url(#paint0_linear_182_438)"
              stroke-width="8"
              stroke-linecap="round"
              stroke-dasharray="80 40"
            />
            <defs>
              <linearGradient
                id="paint0_linear_182_438"
                x1="345.659"
                y1="4"
                x2="345.659"
                y2="1004"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#F80057" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <img src="~@/images/rm-lt-01.webp" class="lt01" />
        <img src="~@/images/rm-lt-02.webp" class="lt02" />
        <img src="~@/images/rm-lt-03.webp" class="lt03" />
        <img src="https://lunartics.s3.amazonaws.com/assets/full-moon.webp" class="ltmoon" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  position: relative;
  max-width: 970px;
  margin: auto;
  display: flex;
  flex-direction: column;

  h1 {
    display: flex;
    justify-content: center;
    font-size: 64px;
    align-items: center;
    gap: 1px;
    margin-bottom: 50px;
    img {
      width: 36px;
      height: auto;
      object-fit: contain;
    }

    span {
      font-family: "Cubano";
    }
  }

  .roadmap-graphic {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    img {
      position: absolute;
    }

    .lt01, .lt02, .lt03 {
      animation: floating 3s ease-in-out infinite;
      width: 300px;
    }

    .lt01 {
      right: 20px;
    }
    .lt02 {
      top: 340px;
      left: -60px;
      animation-delay: -500ms;
    }
    .lt03 {
      top: 740px;
      right: 0;
      animation-delay: -1800ms;
    }

    .ltmoon {
      width: 297px;
      bottom: 20px;
      left: 120px;
    }
  }
  .roadmap-path {
    position: absolute;
    top: 140px;
    left: 50%;
    max-width: 892px;
    width: 100%;
    transform: translateX(-50%);
    overflow: visible;
  }

  .text-emphasis {
    color: #f80156;
  }
  
  &::v-deep {
    .text-emphasis {
      color: #f80156;
      font-weight: bold;
    }
  }

  .map-section {
    display: flex;
    margin-bottom: 205px;
  }

  .map-number {
    font-family: Cubano;
    font-size: 90px;
    text-align: right;
    min-width: 1.5em;
    margin-right: 47px;
  }
  .map-content {
    max-width: 459px;
  }
  .map-title {
    font-family: Cubano;
    font-size: 38px;
    margin-bottom: 18px;

    span {
      font-family: Cubano;
    }
  }
  .map-text {
    font-size: 17px;
    margin-bottom: 28px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .blast-off {
    margin-bottom: 175px;
    align-self: flex-end;
  }
  .mission {
    margin-bottom: 287px;
  }
  .orbital {
    align-self: flex-end;
  }

  @media (max-width: 840px) {
    max-width: 400px;
    .roadmap-path {
      width: 350px;
      height: 1200px;
      top: 180px;
    }
    .roadmap-graphic {
      img {
        width: 167px;
      }
      .lt01, .lt02, .lt03 {
        width: 150px;
      }
      .lt01 {
        top: 120px;
        right: 0px;
      }
      .lt02 {
        top: 380px;
        left: -30px;
      }
      .lt03 {
        top: 680px;
        right: -20px;
      }

      .ltmoon {
        width: 125px;
        bottom: 220px;
        left: 0px;
      }

      .svg-crop {
        height: 1000px;
        overflow: hidden;
        position: relative;
      }
    }
    .map-section {
      flex-direction: column;
      padding: 0 22px;
      max-width: 360px;
      margin-bottom: 88px;
    }
    .map-number {
      font-size: 38px;
      text-align: left;
    }
    .map-content {
      max-width: 242px;
    }
    .map-title {
      font-size: 20px;
    }
    .map-text {
      font-size: 12px;
    }
  }
}
.stars {
  background-image: url("/images/stars-bright.webp");
  background-size: cover;
  background-size: 1700px;
  mask: url("/images/star-mask.png");
  mask-position: 0px 50%;
  animation: twinkle 48s linear infinite;
  mask-size: 1300px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.roadmap {
  position: relative;
}

@keyframes twinkle {
  from {
    mask-position: 0px 50%;
  }
  to {
    mask-position: 1300px 50%;
  }
}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 8px); }
    100%   { transform: translate(0, -0px); }   
}
</style>
