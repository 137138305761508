<template>
  <div class="terms">
    <h1>Privacy Policy</h1>

    <div class="section">
      The Lunartics is a collection of digital artworks (NFTs) running on the
      Ethereum network. This website is only an interface allowing participants
      to exchange digital collectibles. Users are entirely responsible for the
      safety and management of their own private Ethereum wallets and validating
      all transactions and contracts generated by this website before approval.
      Furthermore, as the Lunartics smart contract runs on the Ethereum network,
      there is no ability to undo, reverse, or restore any transactions.
    </div>

    <h1>Privacy Policy</h1>
    <div class="section">
      Please review our <a href="/privacy">Privacy Policy</a>, which also governs your use of the Lunartics NFTs, for information on how we collect, use and share your information.
    </div>

    <h1>OWNERSHIP</h1>
    <div class="section">
      i. You own the NFT. Each Lunartic is an NFT on the Ethereum blockchain.
      When you purchase an NFT, you own the underlying Lunartic completely.
      Ownership of the NFT is mediated entirely by the Smart Contract and the
      Ethereum Network: at no point may we seize, freeze, or otherwise modify
      the ownership of any Lunartics NFT.
    </div>
    <div class="section">
      ii. Personal Use. Subject to your continued compliance with these Terms,
      The Lunartics grants you a worldwide, non-exclusive, royalty-free license
      to use, copy, and display the Art, along with any extensions that you
      choose to create or use, solely for the following purposes: (i) for your
      own personal, non-commercial use; (ii) as part of a marketplace that
      permits the purchase and sale of your Lunartics / NFT, provided that the
      marketplace cryptographically verifies each Lunartics owner’s rights to
      display the Art for their Lunartics NFT to ensure that only the actual
      owner can display the Art; or (iii) as part of a third party website or
      application that permits the inclusion, involvement, or participation of
      your Lunartic, provided that the website/application cryptographically
      verifies each Lunartics owner’s rights to display the Art for their
      Lunartics NFT to ensure that only the actual owner can display the Art,
      and provided that the Art is no longer visible once the owner of the
      Lunartics NFT leaves the website/application.
    </div>

    <div class="section">
      iii. Commercial Use. Subject to your continued compliance with these
      Terms, Lunartics grants you an unlimited, worldwide, non-exclusive license
      to use, copy, and display the Art for the purpose of creating merchandise
      and media based upon the Art (“Commercial Use”). Examples of such
      Commercial Use would e.g. be the use of the Art to produce and sell
      merchandise products (T-Shirts etc.) displaying the Art. You will be
      entitled to retain any revenue that you generate through Commercial Use.
      You can sell its likeness, make derivatives of it or you can give others
      permission to use it. We’ve given licensing rights over for the Lunartics
      NFT’s, but not our logo, names (Lunartics), site images, or other
      marketing material. If it isn’t your Lunartics NFT, it isn’t your
      Intellectual Property. Most of these logos, names, and images related to
      Lunartics have either trademarked pending status or are the intellectual
      property of the artist(s).
    </div>

    <div class="section">
      That said, just because our logo may appear somewhere on your Lunartics
      NFT, that does not give you the right to use that logo other than on the
      Lunartics NFT itself or on a derivative of that Lunartics NFT. You can’t
      take the logo and use it to brand an item for sale or use it for marketing
      of a product. We don’t mind personal use or fan art usage of our logos,
      just so long as you don’t try and sell them. Regarding the term
      “Lunartics”. We allow usage of the term Lunartics only when used in
      conjunction with your Lunartics Token ID #. For instance, you can’t use
      Lunartics alone on merch or NFTs, but you can use Lunartics#2383,
      Lunartics#1567 etc. Think of it like this: You own your Lunartics NFT, not
      Lunartics, so accurately representing yourself, the brand, and/or product,
      while not infringing on our brand, is important.
    </div>

    <div class="section">
      iv. Animation. Lunar-C Corp. reserves the right to use any Art within The
      Lunartics series for any reason, including but not limited to including
      the Art as part of The Lunartics animations, without any obligations to
      you. All rights not expressly granted above are reserved by the Lunar-C
      Corp. For clarity, Lunar-C Corp. will have the right to utilize the Art as
      part of The Lunartics animations to generate revenue and will be solely
      entitled to retain any such revenue.
    </div>
    <div class="section">WARRANTY DISCLAIMER</div>
    <div class="section">
      THE WEBSITE AND ITS CONNECTED SERVICES (THE “SERVICES”), ANY CONTENT
      CONTAINED THEREIN, AND ANY NFTS (INCLUDING ASSOCIATED ART) LISTED THEREIN
      ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES,
      REPRESENTATIONS, OR GUARANTEES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
      LUNAR-C CORP. AND ITS SUPPLIERS MAKE NO WARRANTY THAT THE SERVICES OR
      NFTS: (I) WILL MEET YOUR REQUIREMENTS; (II) WILL BE AVAILABLE ON AN
      UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (III) WILL BE
      ACCURATE, RELIABLE, COMPLETE, OR SAFE. LUNAR-C CORP. DISCLAIMS ALL OTHER
      WARRANTIES REPORTS, OR GUARANTEES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
      LIMITATION, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR
      A PARTICULAR PURPOSE, QUIET ENJOYMENT, TITLE OR NON-INFRINGEMENT, TO THE
      MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AS TO THE SERVICES, ANY
      CONTENT CONTAINED THEREIN AND ANY NFTS LISTED THEREIN.
    </div>
    <div class="section">INDEMNITY</div>
    <div class="section">
      You will indemnify, defend (at Lunar-C Corp.’s option) and hold Lunar-C
      Corp. and its officers, directors, employees and agents, harmless from and
      against any claims, disputes, demands, liabilities, damages, losses, and
      costs and expenses, including, without limitation, reasonable legal and
      accounting fees arising out of or in any way connected with (a) your
      access to or use of the Services, or (b) your violation of these Terms &
      Conditions. You may not settle or otherwise compromise any claim subject
      to this Indemnity Section without Lunar-C Corp.’s prior written approval.
    </div>
    <div class="section">LIMITATION OF LIABILITY</div>
    <div class="section">
      i. TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER LUNAR-C CORP. NOR ITS
      SERVICE PROVIDERS INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE
      SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR
      CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST
      SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE
      INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE
      SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS &
      CONDITIONS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR NFTS,
      WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT
      LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT LUNAR-C CORP. OR
      ITS SERVICE PROVIDERS HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH
      DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED
      OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
      LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE
      ABOVE LIMITATION MAY NOT APPLY TO YOU.
    </div>
    <div class="section">
      ii. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE AGGREGATE
      TOTAL LIABILITY OF LUNAR-C CORP. AND ITS AGENTS, REPRESENTATIVES, AND
      AFFILIATES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS & CONDITIONS
      OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR NFTS EXCEED THE
      AMOUNTS YOU HAVE PAID OR ARE PAYABLE BY YOU TO LUNAR-C CORP. FOR USE OF
      THE SERVICES OR ONE HUNDRED U.S. DOLLARS ($100), IF YOU HAVE NOT HAD ANY
      PAYMENT OBLIGATIONS TO LUNAR-C CORP., AS APPLICABLE.
    </div>
    <div class="section">
      iii. THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
      FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN LUNAR-C CORP. AND
      YOU.
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
//import config from '/src/config';

export default {
  mounted() {
    window.scroll(0, 0);
  },

  data() {
    return {
      projectNameLegal: ''//config.lang.projectNameLegal
    };
  },

  setup() {
    const router = useRouter();
    const goBack = () => {
      router.go(-1);
    };

    return {
      goBack
    };
  }
};
</script>
<style scoped lang="scss">
.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: rgb(29, 29, 29);
  padding: 10px 0px;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  animation-duration: 7s;
  animation-name: rainbowBorder;
  animation-iteration-count: infinite;

  align-items: center;
  button {
    margin-left: 60px;
    font-size: 14px;
    font-family: PressStart;
    animation-duration: 10s;
    animation-name: rainbowText, rainbowBorder;
    animation-iteration-count: infinite;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid white;
    transition: background-color 200ms ease-in;
    color: white;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      animation-name: rainbowBackground;
    }

    &:active {
      animation-name: none;
      background-color: rgb(255, 0, 191);
    }
  }

  img {
    height: 100px;
    width: 200px;
    justify-self: center;
  }
}
.terms {
  padding: 100px;
  padding-top: 130px;
  max-width: 1600px;
  color: white;
  text-align: left;
  margin: auto;
  height: 100%;
  h1 {
    font-family: 'Cubano';
    font-size: 50px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .section {
    margin-bottom: 20px;
    font-size: 16px;
  }
}

a {
  color: rgb(255, 0, 191);
}

@media (max-width: 700px) {
  .terms {
    padding: 30px;
    padding-top: 120px;
  }
}
</style>
