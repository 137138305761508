<template>
  <div class="footer">
    <router-link to="/" class="logo" href="#splash" v-smooth-scroll>
      <img src="https://lunartics.s3.amazonaws.com/assets/lunartics-logo.webp" alt="Footer Logo" />
    </router-link>

    <div class="menuLinks">
      <template v-if="isHome">
        <a href="#trailer" class="link" v-smooth-scroll>Trailer</a>
        <a href="#roadmap" class="link" v-smooth-scroll>Roadmap</a>
        <a href="#faq" class="link" v-smooth-scroll>Faq</a>
        <a href="#team" class="link" v-smooth-scroll>Team</a>
      </template>
      <div class="link" @click="routeTo('/terms')">Terms</div>

      <!-- <a href="#specs" class="link" v-smooth-scroll>Specs</a>
    <a href="#club" class="link" v-smooth-scroll>Club Benefits</a>
    <a href="#roadmap" class="link" v-smooth-scroll>Roadmap</a>
    <a href="#fairdrop" class="link" v-smooth-scroll>Fair Drop</a>
      <a href="#faq" class="link" v-smooth-scroll>FAQ</a>-->

      <div class="link" @click="openUrl(config.discordUrl)">
        <DiscordIcon />
      </div>
      <div class="link" @click="openUrl(config.twitterUrl)">
        <TwitterIcon />
      </div>
    </div>

  </div>
</template>
<script>
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';
import config from '@/config/index'

export default {
  components: {
    DiscordIcon,
    TwitterIcon
  },

  props: {
    isHome: Boolean
  },

  methods: {
    openUrl(url) {
      window.open(url, '_blank');
    },
    routeTo(path) {
      this.$router.push(path);
    }
  },
  data: () => ({
    config
  })
};
</script>
<style scoped lang="scss">
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
  background: #0e173a;

  .menuLinks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .logo {
    max-width: 258px;
    margin-right: auto;
    margin-bottom: 0px;
    cursor: pointer;
    transition: all 500ms ease;

    img {
      height: auto;
      width: 100%;
      object-fit: contain;
    }

    &:active {
      transform: scale(0.95);
    }
  }

  .link {
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-radius: 10px;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #fff;
    text-decoration: none;
    font-size: 22px;
    background-color: transparent;
    border: none;
    height: fit-content;
    white-space: nowrap;
    font-family: "Cubano", sans-serif;

    &:hover {
      cursor: pointer;
      background-color: hsla(0, 0%, 100%, 0.1);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    flex-wrap: wrap;
    justify-content: center;
    .logo {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .terms {
    justify-content: center;
  }
}

@media (max-width: 900px) {
  .footer {
    .logo {
    }
  }
}
</style>
