<template>
  <div class="container">
    <div class="info">
      <div class="title">
        <span>TR</span> <RocketA />
        <span>ITS</span>
      </div>
      <!-- <p class="description">need copy here</p> -->
      <div class="image-grid">
        <template
            v-for="(trait, index) in traits"
            :key="index"
        >
          <img
            :src="imgSrc(index)"
            class="trait-preview"
            :class="[ toggleClass(index), {edge: (index + 1) % 3 === 0} ]"
            @mouseenter="toggleMouseoverTraitIndex(index)"
            @mouseleave="toggleMouseoverTraitIndex(null)"
          />
        </template>
      </div>
      <div class="mobile-image-wrapper">
        <img :src="imgSrc(mouseoverTraitIndex)" />
      </div>
    </div>
    <div class="traits-wrapper">
      <div
        v-for="(trait, index) in traits"
        :key="index"
        class="trait"
        :class="toggleClass(index)"
        @mouseenter="toggleMouseoverTraitIndex(index)"
        @mouseleave="toggleMouseoverTraitIndex(null)"
        @click="toggleMouseoverTraitIndex(index)"
      >
        <div class="info">
          <span class="count">{{ trait.count }} </span>
          <span class="title">{{ trait.title }}</span>
        </div>
        <img class="icon" :src="trait.icon" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import RocketA from '@/components/common/RocketA.vue'

const traits = [
  {
    title: 'body',
    count: '25',
    icon: 'https://lunartics.s3.amazonaws.com/assets/icons/body.webp',
    display: '/images/traits/body.webp',
    preview: '/images/traits/body-preview.webp',
  },
  {
    title: 'bottom',
    count: '26',
    icon: 'https://lunartics.s3.amazonaws.com/assets/icons/bottoms.webp',
    display: '/images/traits/bottom.webp',
    preview: '/images/traits/bottom-preview.webp',
  },
  {
    title: 'eyes',
    count: '33',
    icon: 'https://lunartics.s3.amazonaws.com/assets/icons/eyes.webp',
    display: '/images/traits/eyes.webp',
    preview: '/images/traits/eyes-preview.webp',
  },
  {
    title: 'hat',
    count: '26',
    icon: 'https://lunartics.s3.amazonaws.com/assets/icons/hat.webp',
    display: '/images/traits/hat.webp',
    preview: '/images/traits/hat-preview.webp',
  },
  {
    title: 'mouth',
    count: '29',
    // icon: 'https://lunartics.s3.amazonaws.com/assets/icons/mouth.webp',
    icon: '/images/mouth-icon.svg',
    display: '/images/traits/mouth.webp',
    preview: '/images/traits/mouth-preview.webp',
  },
  {
    title: 'shoes',
    count: '27',
    icon: 'https://lunartics.s3.amazonaws.com/assets/icons/sneaker.webp',
    display: '/images/traits/shoes.webp',
    preview: '/images/traits/shoes-preview.webp',
  },
  {
    title: 'trail',
    count: '27',
    icon: 'https://lunartics.s3.amazonaws.com/assets/icons/trail.webp',
    display: '/images/traits/trail.webp',
    preview: '/images/traits/trail-preview.webp',
  },
  {
    title: 'upper',
    count: '27',
    icon: 'https://lunartics.s3.amazonaws.com/assets/icons/top.webp',
    display: '/images/traits/upper.webp',
    preview: '/images/traits/upper-preview.webp',
  },
  {
    title: 'background',
    count: '24',
    icon: 'https://lunartics.s3.amazonaws.com/assets/icons/background.webp',
    display: '/images/traits/bg.webp',
    preview: '/images/traits/bg-preview.webp',
  }
];

const mouseoverTraitIndex = ref(null);

const toggleMouseoverTraitIndex = index => {
  mouseoverTraitIndex.value = index;
};

const toggleClass = index => {
  if (mouseoverTraitIndex.value !== null) {
    if (index === mouseoverTraitIndex.value) return 'selected';
    else return 'disabled';
  }
  return '';
};
const imgSrc = index => {
  if (mouseoverTraitIndex.value !== null) {
    if (index === mouseoverTraitIndex.value) return traits[index].display;
  }
  return traits[index]?.preview || traits[0]?.preview
}
</script>
<style scoped lang="scss">
.container {
  padding: 140px 40px;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}

.info {
  .title {
    display: flex;
    font-size: 64px;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
    img {
      width: 36px;
      height: auto;
      object-fit: contain;
    }

    span {
      font-family: 'Cubano';
    }
  }

  .description {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 18px;
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(140px, 200px));
    gap: 16px;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      transition: all ease 200ms;
      border-radius: 20px;
      border: 2px solid rgba(255,255,255,.2);
    }

    .selected {
      &.edge {
        transform-origin: 100% 50%;
      }
      transform: scale(1.3);
      box-shadow: 0px 0px 26.782px #f80057;
      border-radius: 20px;
      z-index: 10;
      border: none;
    }

    .disabled {
      opacity: 0.2;
    }
  }

  .mobile-image-wrapper {
    display: none;
    width: 100%;
    height: auto;
    max-width: 620px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 20px;
    }
  }
}

.traits-wrapper {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  width: 80%;
  align-self: flex-end;
  max-width: 500px;

  .trait {
    background: rgba(196, 196, 196, 0.1);
    border-radius: 15px;
    padding: 18px;
    display: flex;
    width: 100%;
    transition: all ease 200ms;
    span {
      font-family: 'Cubano';
      font-size: 30px;
      font-weight: normal;
    }

    .info {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .count {
      margin-right: 10px;
      color: #f80057;
    }

    .title {
      margin-bottom: 0px;
    }

    .icon {
      width: 36px;
      height: auto;
      margin-left: 16px;
      object-fit: contain;
    }

    &:hover, &.selected {
      transform: scale(1.075);
      background-color: #f80057;

      .count {
        color: white;
      }
    }
  }
}

@media (max-width: 800px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  }

  .info .title {
    justify-content: center;
  }

  .traits-wrapper {
    margin: auto;
    width: 100%;
    max-width: 632px;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .trait {
      span {
        font-size: 18px;
      }
      &:nth-child(8) {
        display: none;
      }
    }
  }
}

@media (max-width: 800px) {
  .info {
    .image-grid {
      display: none;
    }

    .mobile-image-wrapper {
      display: flex;
    }
  }

  .traits-wrapper {
    .trait {
      .info {
        flex-direction: column;
        align-items: center;
        gap: 5px;
        text-align: center;

        .count {
          margin: 0;
        }
      }
      .icon {
        display: none;
      }
    }
  }
}
</style>
