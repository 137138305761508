import { ref, computed } from 'vue'
import { useStore } from 'vuex';
import useEthereum from './useEthereum';

export default function useShopConfig() {
  const store = useStore();

  let isLoading = ref(true);

  const loadShopConfig = async () => {
    // delay to ensure all ABIs have loaded
    // not needed once we load ABIs directly instead of via http/get
    await new Promise(resolve => setTimeout(resolve, 100));

    // console.info("loading shop config at address=", config.shopAddress[store.state.ethers.networkId]);
    await store.dispatch(
      'mint/loadShopConfig',
      {},
      {
        root: true
      }
    );
    isLoading.value = false;
  };

  useEthereum(() => {
    loadShopConfig();
  })

  let shopConfig = computed(() => store.state.mint.shopConfig)

  return {
    shopConfig,
    loadShopConfig,
    isLoading
  }
}
