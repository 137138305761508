'use strict';

// Specified actions for mutating the alert state.
export default {
  // Display a success-themed alert.
  success({ commit }, { message, duration }) {
    commit('success', { message, duration });
  },

  // Display an information-themed alert.
  info({ commit }, { message, duration, metadata }) {
    // TODO: actually identify and process the metamask error
    // ignore any transient metamask RPC errors
    if (message && message == "header not found") {
      commit('clear');
      return;
    }
    commit('info', { message, duration, metadata });
  },

  // Display a warning-themed alert.
  warning({ commit }, { message, duration }) {
    // TODO: actually identify and process the metamask error
    // ignore any transient metamask RPC errors
    if (message && message == "header not found") {
      commit('clear');
      return;
    }
    commit('warning', { message, duration });
  },

  // Display an error-themed alert.
  error({ commit }, { message, duration }) {
    // TODO: actually identify and process the metamask error
    // ignore any transient metamask RPC errors
    if (message && message == "header not found") {
      commit('clear');
      return;
    }
    commit('error', { message, duration });
  },

  // Display an alert with the default theme.
  default({ commit }, { message, duration }) {
    commit('default', { message, duration });
  },

  updateDeployedToastIds({ commit }, { deployedToastId }) {
    commit('updateDeployedToastIds', { deployedToastId });
  },

  // Clear all alerts.
  clear({ commit }) {
    commit('clear');
  },

  // TODO
  showWalletModal({ commit }) {
    commit('showWalletModal');
  },
  hideWalletModal({ commit }) {
    commit('hideWalletModal');
  }
};
