<template>
  <div class="appContainer" id="top">
    <div class="main">
      <router-view />
    </div>
  </div>
</template>
<script>
'use strict';

// Imports.
import { mapState, useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { computed } from 'vue';
// Component imports.

// Set up the default application component.

export default {
  components: { },

  setup(props, context) {
    const router = useRouter();
    const store = useStore();

    const isHome = computed(() => {
      return router.currentRoute.value.path === '/';
    });

    const transparentBg = computed(() => {
      if (router.currentRoute.value.path === '/terms') {
        return false;
      } else {
        return true;
      }
    });
    return {
      router,
      isHome,
      transparentBg
    };
  },

  computed: {
    ...mapState({
      alert: state => Object.assign({}, state.alert)
    })
  },

  mounted() {
    this.$store.$toasts = this.$toasts;
    this.$store.commit('checkWalletConnectStore');
  },

  watch: {
    alert: {
      deep: true,
      handler: function (newAlert) {
        let message = newAlert.message;
        if (this.lastMessage === message) {
          // TODO remove this once alert hijacking is removed
          return;
        }
        let type = newAlert.type;
        let duration = newAlert.duration;
        let metadata = newAlert.metadata ? newAlert.metadata : {};

        if (message && type) {
          this.$toasts.base('', {
            message: message,
            type: type,
            duration: duration,
            metadata: metadata
          });
          this.lastMessage = message;
        } else {
          // TODO: dispatch a clear event.
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.appContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  .main {
    min-height: 100vh;
    height: 100%;
    .homeHeader {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
