<template>
  <Ethereum :callback="initialize" />

  <Header transparentBg />

  <BGFixed />
  <div class="networkWarning" v-show="networkWarning">You are not connected to the correct network</div>
  <div v-if="!isConnected" style="margin-top:200px;">
    <ConnectWallet />
  </div>
  <div v-else-if="isLoading" class="skeleton">
    <MintSkeleton />
  </div>
  <div v-else class="mint">
    <MintCard
      :started="started"
      :canMint="canMint"
      :isWhitelist="isWhitelist"
      :mintPrice="parseFloat(currentPrice)"
      :maxMintQty="transactionCap"
      :allowedQty="canMintQty"
      :totalMinted="totalMinted"
      :mintSupply="totalCap"
      @mint="mint"
    >
      <template #countdown>
        <p class="subtext countdown" :class="{ animate: !isLoading && !isLoadingShopConfig }">
          {{ formattedDaysLeft }}
          <!-- -->
          <span class="countdown-label">D</span>
          {{ formattedHoursLeft }}
          <!-- -->
          <span class="countdown-label">H</span>
          {{ formattedMinutesLeft }}
          <!-- -->
          <span class="countdown-label">M</span>
          {{ formattedSecondsLeft }}
          <!-- -->
          <span class="countdown-label">S</span>
        </p>
      </template>
    </MintCard>
  </div>


</template>
<script setup>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { log } from '/src/utility';

// Component imports.
import Header from '/src/pages/layout/Header.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import ConnectWallet from '/src/components/common/ConnectWallet.vue';
import MintSkeleton from './components/MintSkeleton.vue';
//import config from '/src/config';
import useInterval from '/src/utility/useInterval'
import useCountdown from '/src/utility/useCountdown'
import BGFixed from '/src/components/ui/BGFixed.vue'
import MintCard from './components/MintCard.vue'

const debug = ref(true);
const isLoading = ref(true);
const transactionSubmitting = ref(false)
const store = useStore();
const owned = computed(() => store.state.mint.owned);

// #####
// leaving behind skeleton-code based on
// existing drops-template functionality for public mint
// #####

const wlAllowances = computed(() => {
  let allocationAmounts = [];
  for(let i = 1; i <= whitelistAllocRemaining.value; i++){
    allocationAmounts.push(i);
  }
  return allocationAmounts;
});

const wlQtySelection = ref(1);
const handleWlQtySelection = (selection) => {
  wlQtySelection.value = selection;
}

const publicAllowances = computed(() => {
  let allocationAmounts = [];
  for(let i = 1; i <= publicAllocRemaining.value; i++){
    allocationAmounts.push(i);
  }
  return allocationAmounts;
});

const mintQtySelection = ref(1);
const handleSelection = (selection) => {
  mintQtySelection.value = selection;
};


const quantityRemaining = computed(() => totalCap.value - sold.value);
// #####
// ...until here...
// #####

onMounted(() => {
  window.scroll(0, 0);

  // retrieve shop config (when wallet not connected, would rely on infura provider)
  //loadShopConfig();
});

const isConnected = computed(() => {
  return store.state.ethers.canSign;
});

let now = ref(new Date());
setInterval(() => {
  now.value = new Date();
}, 1000);

const isLive = computed(() => {
  return store.getters['mint/startDate'] < now.value;
});

const wlPurchaseItem = async ({qty = 1} = {}) => {
  await store.dispatch(
    'mint/wlMint',
    {
      qty
    },
    { root: true }
  );
  await loadShopConfig();
  await getCounts();
};


const publicPurchaseItem = async ({qty = 1} = {}) => {
  // if whitelist and dutchauction period is over
  if (!isWhitelist.value && !isPublicMint.value) {
    await store.dispatch(
      'alert/error',
      {
        message: 'Mint period closed',
        duration: 10000
      },
      { root: true }
    );

    return;
  }

  // otherwise, mint....
  await store.dispatch(
    'mint/mint',
    {
      qty
    },
    { root: true }
  );

  await loadShopConfig();
  await getCounts();
};

let mint = async ({qty = 1} = {}) => {
  console.log('minting nfts', qty)

  transactionSubmitting.value = true

  if (isWhitelist.value) {
    await wlPurchaseItem({qty})
  } else {
    await publicPurchaseItem({qty})
  }

  transactionSubmitting.value = false
}
const dummy = async() => {}

const initialize = async () => {
  log.info('initialize');

  isLoading.value = true; // this controls the initial skeletor+

  if (isConnected.value) {
    await loadShopConfig();
    await loadMerkleData();
    await loadCurrentPrice();
    await getCounts();
  }
  console.log("network", store.state.ethers.networkId)
  console.log("totals", totalCap.value, sold.value);
  isLoading.value = false;
}

const networkWarning = computed(() => {
  return (store.state.ethers.networkId) ? store.state.ethers.networkId != "0x1" : false;
});
//
// const loadAll = async () => {
//   await Promise.all([
//     //loadMerkleData(),
//     loadShopConfig(),
//     loadCurrentPrice(),
//     //loadOwnedItems(),
//     //loadTokenInfo(),
//   ]);
// };

const loadCurrentPrice = async () => {
  await store.dispatch(
    'mint/currentPrice',
    {},
    { root: true }
  );
  return store.state.mint.currentPrice;
}

const loadShopConfig = async () => {
  //log.info("loading shop config at address=", config.shopAddress[store.state.ethers.networkId]);
  await store.dispatch(
    'mint/loadShopConfig',
    {},
    {
      root: true
    }
  );
}

const loadMerkleData = async () => {
  await store.dispatch(
    'mint/loadMerkleData',
    {},
    {
      root: true
    }
  );
}

const getCounts = async () => {
  if (isReady.value) {
    await store.dispatch(
      'mint/getCounts',
      {},
      {
        root: true
      }
    );
  };
}

// update current item price every 5 sec
useInterval(() => {
  //loadCurrentPrice().then();
  getCounts();
  //loadShopConfig().then();
  //loadOwnedItems()
}, 1000 * 5);

const isReady = computed(() => {
  return store.state.mint.shopConfig != null;
});

const isWhitelist = computed(() => {
  // uses shopConfig start/end times
  return isReady.value && (
    now.value >= wl_ethStartTime.value && now.value <= wl_ethEndTime.value);
});

const hasWlProof = computed(() => {
  return isReady.value && (store.state.mint.merkleProofs.length > 0);
});

const isPublicMint = computed(() => {
  // uses shopConfig start/end times
  return isReady.value && (
    now.value >= publicStartTime.value && now.value <= publicEndTime.value);
});

const isOver = computed(() => {
  // uses shopConfig start/end times
  return !isReady.value || (now.value >= publicEndTime.value);
});

// const isComingSoon = computed(() => {
//   // uses shopConfig start/end times
//   return isReady.value && ((now.value < wl_tokenStartTime.value) && (now.value < wl_ethEndTime.value) && (now.value < publicEndTime.value));
// });

// computed/helper functions for shop config data
const publicStartDate = computed(() => {
  return new Date(parseInt(publicStartTime.value));
});

const publicStartTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.publicStartTime;
});

const publicEndDate = computed(() => {
  return new Date(parseInt(publicEndTime.value));
});

const publicEndTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.publicEndTime;
});

const publicStartingPrice = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.publicStartingPrice;
});

const publicEndingPrice = computed(() => {
  if (!isReady.value) return 1;
  return store.state.mint.shopConfig.publicEndingPrice;
});
//
// const wl_tokenStartTime = computed(() => {
//   if (!isReady.value) return now.value;
//   return store.state.mint.shopConfig.tokenStartTime;
// });
//
// const wl_tokenEndTime = computed(() => {
//   if (!isReady.value) return now.value;
//   return store.state.mint.shopConfig.tokenEndTime;
// });
//
// const wl_tokenStartingPrice = computed(() => {
//   if (!isReady.value) return 0;
//   return store.state.mint.shopConfig.tokenStartingPrice;
// });

const wl_ethStartTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.ethStartTime;
});

const whitelistStartDate = computed(() => {
  return new Date(parseInt(wl_ethStartTime.value));
});

const wl_ethEndTime = computed(() => {
  if (!isReady.value) return now.value;
  return store.state.mint.shopConfig.ethEndTime;
});

const whitelistEndDate = computed(() => {
  return new Date(parseInt(wl_ethEndTime.value));
});


const wl_ethStartingPrice = computed(() => {
  if (!isReady.value) return 0;
  return store.state.mint.shopConfig.ethStartingPrice;
});

const countdownDate = computed(() => {
  return isWhitelist.value ? publicStartDate.value : whitelistStartDate.value
})

let {
  formattedDaysLeft,
  formattedHoursLeft,
  formattedMinutesLeft,
  formattedSecondsLeft,
} = useCountdown({ endDate: countdownDate });

const sold = computed(() => {
  if (!isReady.value) return 0;
  return store.state.mint.soldCount;
});

const totalCap = computed(() => {
  if (!isReady.value) return 0;
  return store.state.mint.shopConfig.totalCap.toNumber();
});

const callerCap = computed(() => {
  if (!isReady.value) return 1;
  return store.state.mint.shopConfig.callerCap.toNumber();
});

const transactionCap = computed(() => {
  if (!isReady.value) return 1;
  return store.state.mint.shopConfig.transactionCap.toNumber();
});

//
let totalMinted = computed(() => store.state.mint.soldCount);
let purchaseCount = computed(() => store.state.mint.purchaseCount);

//let totalWhitelistMinted = computed(() => Math.min(totalMinted.value, allowance.value))
let whitelistAllocRemaining = computed(() => Math.max(allowance.value - purchaseCount.value, 0))
let publicAllocRemaining = computed(() => Math.max(callerCap.value - purchaseCount.value, 0))
let canMintQty = computed(() => isWhitelist.value ? whitelistAllocRemaining.value : publicAllocRemaining.value)
let canMint = computed(() => hasWlProof.value || isPublicMint.value)
let started = computed(() => wl_ethStartTime.value < now.value )

const currentPrice = computed(() => {
  const contractCurrentPrice = store.state.mint.currentPrice;
  if (contractCurrentPrice == null && isReady.value) {
    return publicStartingPrice.value;
  }
  return contractCurrentPrice;
});

const currentAddress = computed(() => {
  return store.state.ethers.address;
});

const allowance = computed(() => {
  if (!isReady.value) return 0;
  const proofs = store.state?.mint?.merkleProofs;
  const hasProofs = (proofs && proofs.length > 0);
  return (hasProofs ? store.state.mint.merkleProofs[0].allowance : 0);
});


watch(
  currentAddress, (curAdd, oldAdd) => {
    oldAdd = (oldAdd) ? oldAdd.toLowerCase() : null;
    curAdd = (curAdd) ? curAdd.toLowerCase() : null;
    if(curAdd != oldAdd && oldAdd != null){
      console.log("reset address");
      initialize();
    }
  }
);

watch( isConnected, (is, was) => {
  if(!isLoading.value){
    initialize();
  }
});

let soldOut = computed(() => sold.value >= totalCap.value)

</script>

<style scoped lang="scss">
.skeleton {
  margin-top: 100px;
  padding: 60px 20px;
  padding-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mint {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

}

.networkWarning {
  width:100%;
  text-align:center;
  padding: 10px 0;
  background:#ffaa11;
}

.countdown {
  font-size: 28px;
  span {
    font-family: "Cubano";
    color: #f80057;
  }
  .countdown-label {
    font-size: 0.8em;
  }
}
</style>
