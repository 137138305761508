'use strict';

// Imports.
import { ethersService, merkleService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';
import initializeConfig from '../initialize-config';

let config;
(async () => {
  config = await initializeConfig();
})();

import { log } from '/src/utility'

const big2ts = bn => bn.toNumber() * 1000;

const calcEth = (eth, wei) => {
  return ethers.utils.formatEther(ethers.utils.parseEther(eth).sub(wei));
};

// Accessible error status messages for future localization.
const GenericError = `An error occurred. Please try again.`;
const MESSAGES = {
  'CannotTransferIncorrectAmount()': GenericError,
  'PaymentTransferFailed()': `An error occurred during payment. Please try again.`,
  'CannotVerifyAsWhitelistMember()': `You are not whitelisted.`,
  'CannotExceedWhitelistAllowance()': `You have reached your mint limit during whitelist.`,
  'CannotBuyZeroItems()': GenericError,
  'CannotBuyFromEndedSale()': GenericError,
  'CannotExceedPerTransactionCap()': GenericError,
  'CannotExceedPerCallerCap()': `You have reached your mint limit.`,
  'CannotExceedTotalCap()': `All impostors have been minted.`,
  'CannotUnderpayForMint()': GenericError,
  'RefundTransferFailed()': GenericError,
  'ERC20: transfer amount exceeds balance': `You don't have enough SUPER tokens to mint.`,
  WrongNetwork:
    'We are unable to connect to the right network. Can you try changing networks and reloading?'
};

// Used to check for exceptions that may occur during transition periods
// (wallet is locked, walletconnect is setting up, etc)
const checkException = async er => {
  fncRegex = /call revert exception (.*())\"/g;
  while ((match = fncRegex.exec(er))) {
    return true;
  }
  return false;
};

// Converts 'er' message into a user friendly string
const errMsg = er => {
  let uiErr = er.toString();
  let match = null;

  // see if we have a custom error (and if we do, parse it out)
  let fncRegex = /custom error \'(.*())'\"/g;
  // see custom errors in drop contract
  while ((match = fncRegex.exec(uiErr))) {
    const [, key] = match;
    uiErr = MESSAGES[key];
  }

  // see if we reverted for any reason (and if we do, parse it out)
  fncRegex = /reverted with reason string \'(.*())'\"/g;
  // most likely culprit: 'ERC20: transfer amount exceeds balance'
  while ((match = fncRegex.exec(uiErr))) {
    const [, key] = match;
    uiErr = MESSAGES[key];
  }

  fncRegex = /call revert exception (.*())\"/g;
  // most likely culprit: 'user does not have enough ETH or SUPER
  while ((match = fncRegex.exec(uiErr))) {
    const [, key] = match;
    uiErr = MESSAGES[key];
  }

  if (MESSAGES[er] != null) {
    uiErr = MESSAGES[er];
  }

  // if the network is too slow, we may not properly parse the revert reason...
  if (uiErr == null || uiErr.length > 100) uiErr = GenericError;

  // Return a user-friendly error for the failure.
  return uiErr;
};

// dispatches error message
const processError = async (er, shouldClear, dispatch) => {
  // Return a user-friendly error for the purchase failure.
  if (shouldClear) {
    await dispatch('alert/clear', '', { root: true });
  }
  await dispatch(
    'alert/info',
    {
      message: er,
      duration: 10000
    },
    { root: true }
  );
};


const loadShopConfig = async function(dispatch) {
  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let shopAddress = config.shopAddress[networkId];
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  /// The time when the public sale begins.
  let pStart = await shopContract.startTime();

  /// The time when the public sale ends.
  let pEnd = await shopContract.endTime();

  /// The price at which the public sale is set.
  let pPrice = await shopContract.price();

  /// The total number of items sold by the shop.
  let sold = await shopContract.sold();
  sold = sold.add(config.reservedMintCount);

  /// The maximum number of items from the `collection` that may be sold.
  let totalCap = await shopContract.totalCap();

  /// The maximum number of items that a single address may purchase.
  let callerCap = await shopContract.callerCap();

  /// The maximum number of items that may be purchased in a single transaction.
  let transactionCap = await shopContract.transactionCap();

  const wl = await shopContract.whitelists(0);
  let eStart = wl.startTime;
  let eEnd = wl.endTime;
  let ePrice = wl.price;

  return {
    publicStartTime: pStart.mul(1000).toString(),
    publicEndTime: pEnd.mul(1000).toString(),
    publicStartingPrice: ethers.utils.formatEther(pPrice),
    publicStartingPriceWei: pPrice,
    // tokenStartTime: big2ts(tokStart),
    // tokenEndTime: big2ts(tokEnd),
    // tokenStartingPrice: ethers.utils.formatEther(tokPrice),
    // tokenStartingPriceWei: tokPrice,
    ethStartTime: eStart.mul(1000).toString(),
    ethEndTime: eEnd.mul(1000).toString(),
    ethStartingPrice: ethers.utils.formatEther(ePrice),
    ethStartingPriceWei: ePrice,
    sold: sold,
    transactionCap: transactionCap,
    // totalCap in contract is actual cap - reserved so we need to add reserved
    // back in for accurate total
    totalCap: totalCap.add(config.reservedMintCount),
    callerCap: callerCap,
  };
};

const getSoldCount = async function() {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];

  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );
  let soldCountOffset = config.reservedMintCount;

  let sold = await shopContract.sold();
  sold = sold.add(config.reservedMintCount);


  return (sold) ? sold.toNumber() : 0;
};

const getPurchaseCount = async function () {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];

  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  let purchaseCount = await shopContract.purchaseCounts(address);

  return (purchaseCount) ? purchaseCount.toNumber() : 0;
};

// Get the current price of an item
const currentPrice = async function() {

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];

  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  let currentPrice = await shopContract.price();
  return ethers.utils.formatEther(currentPrice);
};

const wlPurchaseItem = async function( qty, dispatch ) {
  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];
  let signer = await provider.getSigner();

  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    signer
  );

  let wlData = {
    id: 0,
    index: 0,
    allowance: 0,
    proof: []
  };

  let merkleData = await merkleService.loadMerkleData();


  if(merkleData){
    wlData.proof = merkleData[0].proof;
    wlData.index = merkleData[0].index;
    wlData.allowance = merkleData[0].allowance;
  }else{
    //throw;
  }

  let price = await shopContract.price();
  let totalSpend = price.mul(qty);
  console.log("WL MINT", qty, totalSpend.toString(), wlData);

  // let gasPrice = 0;
  // await provider.getGasPrice().then(currentGasPrice => {
  //   gasPrice = ethers.utils.hexlify(parseInt(currentGasPrice));
  // });
  // let gasLimit = await provider.estimateGas(shopContract.mint(qty, wlData, {value: totalSpend}))
  // let gasLimit = ethers.utils.hexlify('0x100000');

  let mintTx = await shopContract.mint(qty, wlData, {
    value: totalSpend
  });

  await dispatch(
    'alert/info',
    {
      message: 'Transaction Submitted',
      metadata: {
        transaction: mintTx.hash
      },
      duration: 300000
    },
    { root: true }
  );
  await mintTx.wait();
  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Transaction Confirmed',
      metadata: {
        transaction: mintTx.hash
      },
      duration: 10000
    },
    { root: true }
  );


};

// Purchase an item from the shop.
const purchaseItem = async function(
  qty,
  dispatch
) {

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];

  let isValid = await ethers.utils.isAddress(shopAddress);
  if (!isValid) {
    return; // TODO: throw useful error.
  }

  // Used while checking for token spend approval.
  let signer = await provider.getSigner();

  // Purchase the item.
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    signer
  );

  let nilLeaf = ethers.utils.solidityKeccak256(
      ['uint256', 'address', 'uint256'],
      [1, ethers.constants.AddressZero, 0]
    );

  let proof = {
    id: 0,
    index: 0,
    allowance: 0,
    proof: [nilLeaf, nilLeaf, nilLeaf]
  };
  //
  // if (mintStore.merkleProofs.length == 0) {
  //   // proof is only verified when whitelist are open, so using spoofed values have no negative impact during public mints
  //
  // } else {
  //   for (let listEntry of mintStore.merkleProofs) {
  //     proof.proof = listEntry.proof;
  //     proof.index = listEntry.index;
  //     proof.allowance = listEntry.allowance;
  //   }
  // }

  /*
   * calculate price: if whitelist, take in consideration shopConfig and token/eth values.
   *                 otherwise, ping shopContract for current price
   */

  let price = await shopContract.price();

  // if (isWhitelist) {
  //   price = isToken
  //     ? mintStore.shopConfig.tokenStartingPriceWei
  //     : mintStore.shopConfig.ethStartingPriceWei;
  //   if (isToken) {
  //     price = ethers.BigNumber.from('0');
  //     let erc20Contract = new ethers.Contract(
  //       config.tokenAddress,
  //       config.erc20ABI,
  //       signer
  //     );
  //     let allowance = ethers.BigNumber.from(0);
  //     try {
  //       allowance = await erc20Contract.allowance(
  //         ethersStore.address,
  //         shopAddress
  //       );
  //     } catch (error) {
  //       await processError(errMsg(error.message), true, dispatch);
  //       // give up
  //       return;
  //     }
  //     // TODO: make a more robust check for the exact required allowance
  //     if (!allowance.gt(0)) {
  //       let approvalTx = null;
  //       try {
  //         approvalTx = await erc20Contract.approve(
  //           shopAddress,
  //           ethers.constants.MaxUint256
  //         ); // TODO: prompt the user for a configurable allowance input
  //       } catch (error) {
  //         await processError(errMsg(error.message), true, dispatch);
  //       }
  //
  //       await dispatch(
  //         'alert/info',
  //         {
  //           message: 'Transaction Submitted',
  //           metadata: {
  //             transaction: approvalTx.hash
  //           },
  //           duration: 300000
  //         },
  //         { root: true }
  //       );
  //       await approvalTx.wait();
  //     }
  //   }
  // } else {
  //   price = await shopContract.price();
  // }
console.log("purchaseItem", qty, price);
  let totalSpend = price.mul(qty);

  let mintTx = await shopContract.mint(qty, proof, {
    value: totalSpend
  });

  await dispatch(
    'alert/info',
    {
      message: 'Transaction Submitted',
      metadata: {
        transaction: mintTx.hash
      },
      duration: 300000
    },
    { root: true }
  );
  await mintTx.wait();
  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Transaction Confirmed',
      metadata: {
        transaction: mintTx.hash
      },
      duration: 10000
    },
    { root: true }
  );

  // if (mintTx != null) {
  //   await dispatch(
  //     'alert/info',
  //     {
  //       message: 'Transaction Submitted',
  //       metadata: {
  //         transaction: mintTx.hash
  //       },
  //       duration: 300000
  //     },
  //     { root: true }
  //   );
  //
  //   await mintTx
  //     .wait()
  //     .then(async result => {
  //       log.info('Result from mint attempt', result);
  //       await dispatch('alert/clear', '', { root: true });
  //       await dispatch(
  //         'alert/info',
  //         {
  //           message: 'Transaction Confirmed',
  //           metadata: {
  //             transaction: mintTx.hash
  //           },
  //           duration: 10000
  //         },
  //         { root: true }
  //       );
  //     })
  //     .catch(async function(error) {
  //       await processError(errMsg(error.message), true, dispatch);
  //     });
  // }
};

// Parse the items owned by a given address.
const loadItems = async function(mintStore, resolveMetadata, dispatch) {
  let provider, network, networkId;
  try {
    provider = await ethersService.getProvider();
    network = await provider.getNetwork();
    networkId = ethers.utils.hexValue(network.chainId);
  } catch (error) {
    return;
  }

  let itemCollectionAddress = config.itemCollections[networkId][0];

  let isValid = await ethers.utils.isAddress(itemCollectionAddress);
  if (!isValid) {
    return; // TODO: throw useful error.
  }

  // Check for token spend approval.
  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();

  let itemContract = new ethers.Contract(
    itemCollectionAddress,
    config.itemABI,
    provider
  );

  // Check relative item transfer events to determine this user's current
  // inventory.
  let ownershipData = {};
  // Single transfer events.
  let filterToWallet = itemContract.filters.Transfer(null, walletAddress)
  let filterFromWallet = itemContract.filters.Transfer(walletAddress, null)
  let singleTransfers = [
    ...await itemContract.queryFilter(filterToWallet),
    ...await itemContract.queryFilter(filterFromWallet),
  ].sort((a, b) => {
    let block = a.blockNumber - b.blockNumber
    if (block !== 0) {
      return block
    }

    return a.transactionIndex - b.transactionIndex
  })
  log.info(
    `Processing ${singleTransfers.length} single transfer events ...`
  );

  for (let t of singleTransfers) {
    ownershipData[t.args.tokenId.toNumber()] = {
      collectionAddress: t.address,
      tokenId: t.args.tokenId.toNumber(),
      blockHash: t.blockHash,
      blockNumber: t.blockNumber,
      data: t.data,
      txHash: t.transactionHash,
      txIndex: t.transactionIndex,
      metadata: null,
      owner: t.args.to
    };
  }

  let myItems = Object.values(ownershipData)
    .filter((tokenData) => tokenData.owner === walletAddress);

  const metadataURI = await itemContract.metadataUri();
  for (let item of myItems) {
    let resp = null;
    if (resolveMetadata) {
      resp = await axios
        .get(metadataURI + item.tokenId)
        .then(resp => {
          return resp.data;
        })
        .catch(err => {
          return { err: err.message };
        });
    }
    item.metadata = resp
  }

  return myItems;
};

// Parse the allowance and number of token owned by a given address.
const loadTokenInfo = async function(
  ethersStore, dispatch
) {

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];
  let signer = await provider.getSigner();

  let erc20Contract = new ethers.Contract(
    config.tokenAddress,
    config.erc20ABI,
    signer
  );
  let allowance = await erc20Contract.allowance(
    ethersStore.address,
    shopAddress
  );
  let balance = await erc20Contract.balanceOf(
    ethersStore.address
  );
  let ethBalance = await provider.getBalance(
    ethersStore.address
  );

  return {
    tokenBalance: balance,
    hasSuper: balance.gt(0),
    tokenAllowance: allowance,
    hasAllowedTokenAccess: allowance.gt(0),
    ethBalance: ethBalance,
    hasEth: ethBalance.gt(0),
  }
};

const approveSuper = async function({ dispatch }) {

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let shopAddress = config.shopAddress[networkId];
  let signer = await provider.getSigner();

  let erc20Contract = new ethers.Contract(
    config.tokenAddress,
    config.erc20ABI,
    signer
  );

  let approvalTx = null;
  let approvalAmount = ethers.constants.MaxUint256
  try {
    approvalTx = await erc20Contract.approve(
      shopAddress,
      approvalAmount
    ); // TODO: prompt the user for a configurable allowance input

    await dispatch(
      'alert/info',
      {
        message: 'Transaction Submitted',
        metadata: {
          transaction: approvalTx.hash
        },
        duration: 300000
      },
      { root: true }
    );
    let result = await approvalTx.wait();
    await dispatch('alert/clear', '', { root: true });
    await dispatch(
      'alert/info',
      {
        message: 'SUPER Approved for Mint',
        metadata: {
          transaction: approvalTx.hash
        },
        duration: 10000
      },
      { root: true }
    );
    return { tx: result, approvalAmount };
  } catch (error) {
    await processError(errMsg(error.message), true, dispatch);
  }
};

// Export the user service functions.
export const mintService = {
  loadShopConfig,
  currentPrice,
  purchaseItem,
  loadItems,
  calcEth,
  errMsg,
  loadTokenInfo,
  approveSuper,
  getSoldCount,
  getPurchaseCount,
  wlPurchaseItem
};
